import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CONFIRM_ORDER_CODE_AND_COMPLETE } from '../../../GraphQL/Mutations';
import { CURRENT_USER_COURIER } from '../../../GraphQL/Queries';
import { useMutation, useLazyQuery } from '@apollo/client';
import { Loading } from '../../../Components/Loading';
import { Validate } from '../../../Helpers/Validate';
import UserContext from '../../../Helpers/UserContext';
import Session from '../../../Helpers/Session';
import OtpInput from 'react-otp-input';
import { BLOCKBUTTON } from '../../../Components/Forms';
import { APIResponse } from '../../../Helpers/General';
import NumberToMoney from '../../../Helpers/numberToMoney';

const ConfirmOrder = () => {
    const myContext = React.useContext(UserContext);
    const navigate = useNavigate();
    const user = myContext.user || Session.get("user");
    const [code, setCode] = React.useState('');
    const [order, setOrder] = React.useState(Session.get("selectedOrder"));
    const [confirmOrderModal, setConfirmOrderModal] = React.useState(false);

    const [refetchUser, {loading}] = useLazyQuery(CURRENT_USER_COURIER, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.currentUserCourier) {
                Session.set("user", data.currentUserCourier);
                myContext.setUser(data.currentUserCourier);
            }
        },
        onError: (error) => {
            console.log(JSON.stringify(error, null, 2));
            APIResponse(error);
            Session.showAlert({ str: error.message, type: 'error' });
            return;
        }
    })
    const [confirmOrder] = useMutation(CONFIRM_ORDER_CODE_AND_COMPLETE, {
        onCompleted: async (data) => {
            console.log(data.confirmOrderCodeAndComplete)
            if (data.confirmOrderCodeAndComplete) {
                setOrder(Session.get("selectedOrder"));
                Session.remove("selectedOrder");
                Session.remove("step");
                setConfirmOrderModal(true);
                await refetchUser();
            }
        },
        onError: (error) => {
            console.log(error);
            APIResponse(error);
            Session.showAlert({ str: error.message, type: 'error' });
        }
    })

    const handleCode = (value: string) => {
        setCode(value.replace(/\D/g, '0'));
    }
    const handleConfirmOrder = () => {
        console.log({ code, id: order.id })
        if (!Validate.string(code) || code.length !== 4) {
            Session.showAlert({ str: 'Please enter a four-digit delivery code.', type: 'error' });
            return;
        }
        if (!Validate.positiveInteger(order.id) || order.id < 1) {
            Session.showAlert({ str: 'The Order is not valid.', type: 'error' });
            return;
        }
        confirmOrder({
            variables: {
                orderId: order.id,
                code: code
            }
        });
    }
    if(loading){
        return <Loading />
    }


    return (
        <>
            <div className={'brand-modal-map w3-animate-bottom'}>
                <div className='w-100 brand-modal-content bg-light px-3' style={{ height: '80svh' }}>
                    <div className='mt-5'>
                        <h3 className='fs-3 fw-bold my-0'>Enter Delivery Code</h3>
                    </div>
                    <div className='mt-3'>
                        <p>
                            A four-digit delivery code has been send to the merchant. Kindly ask for and enter the code to confirm the delivery.
                        </p>
                    </div>
                    <OtpInput
                        value={code}
                        onChange={handleCode}
                        numInputs={4}
                        renderSeparator={<span className=' flex-grow-1'>&nbsp;</span>}
                        containerStyle="d-flex flex-wrap justify-content-center"
                        inputStyle="form-control flex-grow-1 fs-2 fw-bolder text-center"
                        renderInput={(props) => <input {...props} />}
                        inputType='number'
                    />

                    <BLOCKBUTTON
                        className={'btn btn-primary w-100 mt-4'}
                        disabled={code.length !== 4}
                        onClick={handleConfirmOrder}
                    >
                        Confirm Delivery
                    </BLOCKBUTTON>

                    <p className='text-muted text-center mt-3'>The merchant didn't receive the delivery code?</p>

                    <p className='text-primary text-center fw-bold'>Resend Delivery Code</p>
                </div>
                <div
                    className={'brand-modal w3-animate-zoom ' + (confirmOrderModal ? 'd-flex' : 'd-none')}
                    style={{ alignItems: 'center' }}
                >
                    <div
                        className='container bg-light p-3 mx-3'
                        style={{ borderRadius: '16px', maxWidth: '400px' }}
                    >
                        <h3 className='text-center fs-4 text-success'>Delivery Confirmed</h3>
                        <div>
                            <p className='text-center text-wrap'>
                                The sum of <span className='text-primary fw-bold'>&#8358;&nbsp;{NumberToMoney(order.amount)}</span> has been paid into your wallet.
                            </p>
                        </div>

                        <p className='text-center mb-0'>Rating</p>
                        <div className="d-flex align-items-center justify-content-center">
                            <i className="bi bi-star-fill text-warning fs-3 mx-1"></i>
                            <i className="bi bi-star-fill text-warning fs-3 mx-1"></i>
                            <i className="bi bi-star-fill text-warning fs-3 mx-1"></i>
                            <i className="bi bi-star-fill text-warning fs-3 mx-1"></i>
                        </div>
                        <div className='d-flex justify-content-around align-items-center'>

                            <BLOCKBUTTON
                                className='btn-primary mt-4 mb-3'
                                onClick={() => {
                                    setConfirmOrderModal(false);
                                    Session.remove("selectedOrder");
                                    navigate('/wallet');
                                }}
                            >
                                Continue
                            </BLOCKBUTTON>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConfirmOrder;