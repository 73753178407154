interface Location {
  lat: number;
  lng: number;
}

const UserCurrentLocation = (): Promise<Location> => {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject('Geolocation is not supported by your browser');
      return;
    }

    const successHandler = (position: GeolocationPosition) => {
      const { latitude, longitude } = position.coords;
      resolve({ lat: latitude, lng: longitude });
    };

    const errorHandler = (error: GeolocationPositionError) => {
      reject(error.message);
    };

    navigator.geolocation.getCurrentPosition(successHandler, errorHandler);
  });
};

export default UserCurrentLocation;