import React from 'react';
import { Courier } from '../types/CurrentCourier';

interface UserContextType {
    user: Courier | null;
    setUser: React.Dispatch<React.SetStateAction<Courier | null>>;
    config: any;
    setConfig: React.Dispatch<React.SetStateAction<any>>;
}

const UserContext = React.createContext<UserContextType>({
    user: null,
    setUser: () => {},
    config: {},
    setConfig: () => {}
});

export default UserContext;
