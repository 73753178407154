import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import UserContext from '../../Helpers/UserContext';
import Session from '../../Helpers/Session';

const Menu: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const myContext = React.useContext(UserContext);
    const user = myContext.user || Session.get('user');
    const [intendedPath, setIntendedPath] = useState<string>('');
    
    // Define menu items for better maintainability
    const menuItems = [
        { path: '/', icon: 'bi-house-fill', label: 'Home', requiresVerification: false },
        { path: '/order', icon: 'bi-bicycle', label: 'Orders', requiresVerification: true },
        { path: '/wallet', icon: 'bi-wallet', label: 'Wallet', requiresVerification: true },
        { path: '/account', icon: 'bi-person-fill', label: 'Account', requiresVerification: true },
    ];

    const shouldRedirectToVerify = () => {
        const { nin_status, riders_license_status }: any = user?.courierDocumentStatus || {};
        const { status, bike_model, bike_brand, bike_year, bike_plate_number }: any = user?.courierDetails || {};

        return (
            nin_status !== "APPROVED" ||
            riders_license_status !== "APPROVED" ||
            (status !== "Active" || (!bike_model || !bike_brand || !bike_year || !bike_plate_number))
        );
    };

    const isActive = (path: string) => {
        // If we're on the verification page, check against the intended path
        if (location.pathname === '/not-verified') {
            return path === intendedPath;
        }
        
        // Handle nested routes
        if (path === '/') {
            return location.pathname === path;
        }
        return location.pathname.startsWith(path);
    };

    const handleNavigation = (path: string, requiresVerification: boolean) => {
        if (requiresVerification && shouldRedirectToVerify()) {
            setIntendedPath(path); // Store the intended path
            navigate('/not-verified');
        } else {
            setIntendedPath(''); // Clear intended path
            navigate(path);
        }
    };

    return (
        <div className='menu-container bg-light mt-4'>
            <div className='d-flex justify-content-around align-items-center'>
                {menuItems.map((item) => (
                    <div
                        key={item.path}
                        className='w3-center'
                        onClick={() => handleNavigation(item.path, item.requiresVerification)}
                    >
                        <div className={`menu-item ${isActive(item.path) ? 'menu-active' : ''}`}>
                            <div className='d-flex flex-column'>
                                <i className={`bi ${item.icon} fs-3`}></i>
                                <p>{item.label}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Menu;