import React from 'react';
import { BUTTON } from './Forms';

interface ConfirmProps {
  message: string | JSX.Element;
  onConfirm: (confirmed: boolean) => void;
  onCancel: () => void;
  topic: string;
  key: string; // Unique key for each confirmation dialog
  takeActionText?: string;
  cancelText?: string;
}

const Confirm: React.FC<ConfirmProps> = ({ message, onConfirm, topic, key , onCancel,  takeActionText = 'Yes', cancelText = 'No'}) => {

  const handleConfirm = () => {
    onConfirm(true);
  };

  return (
    <div className="confirm-modal" key={key}>
      <div className='w-100 bg-light px-3 mx-2' style={{ borderRadius: '16px', maxWidth: '400px' }}>
        <h3 className='text-center fw-bold fs-4 text-nowrap'>{topic}</h3>
        <p className="text-start fs-6">{message}</p>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <BUTTON className="border border-1 border-dark flex-grow-1 " onClick={onCancel} style={{ maxWidth: '200px' }}>{cancelText}</BUTTON>
          <div className=''></div>
          <BUTTON className="ms-3 btn btn-primary flex-grow-1" onClick={handleConfirm} style={{ maxWidth: '200px' }}>{takeActionText}</BUTTON>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
