import React, { useState, useEffect, useContext } from 'react';
import UserContext from '../../../Helpers/UserContext';
import Session from '../../../Helpers/Session';
import userPhoto from '../../../assets/images/photo.png';
import QB, { QBConfig } from "quickblox/quickblox";
import { QBConfig as QB_CONFIG } from '../../../config/QB_config';
import { useNavigate } from 'react-router-dom';
import { getImage } from '../../../Helpers/FileHandling';

const VoiceCall = () => {
    const myContext = useContext(UserContext);
    const user = myContext.user || Session.get('user');
    const merchantOrder = Session.get("selectedOrder").merchantDetails;
    const navigate = useNavigate();
    const QuickBlox = JSON.parse(Session.getCookie("config")).quickblox;

    let courierChatDetails: any;
    try {
        courierChatDetails = JSON.parse(merchantOrder.chat_details);
    } catch (error) {
        courierChatDetails = merchantOrder.chat_details;
    }
    
    const userChatAuth = {
        login: user.email,
        password: QuickBlox.usersPassword,
    };

    const [isUserAuthorized, setUserAuthorized] = useState(false);
    const [isSDKInitialized, setSDKInitialized] = useState(false);
    const [callSession, setCallSession] = useState<any>(null);

    const prepareSDK = async (): Promise<void> => {
        if ((window as any).QB === undefined) {
            if (QB !== undefined) {
                (window as any).QB = QB;
            } else {
                let QBLib = require('quickblox/quickblox.min');
                (window as any).QB = QBLib;
            }
        }

        const APPLICATION_ID = QB_CONFIG.credentials.appId;
        const AUTH_KEY = QB_CONFIG.credentials.authKey;
        const AUTH_SECRET = QB_CONFIG.credentials.authSecret;
        const ACCOUNT_KEY = QB_CONFIG.credentials.accountKey;
        const CONFIG: QBConfig = {};

        QB.init(APPLICATION_ID, AUTH_KEY, AUTH_SECRET, ACCOUNT_KEY, CONFIG);
    };

    useEffect(() => {
        if (!isSDKInitialized) {
            prepareSDK().then(() => {
                QB.createSession(userChatAuth, async (errorCreateSession: any, session: any) => {
                    if (errorCreateSession) {
                        console.log('Create User Session has error:', JSON.stringify(errorCreateSession));
                    } else {
                        console.log(session);
                        const userId: number = session.user_id;
                        const password: string = session.token;
                        const paramsConnect = { userId, password };

                        QB.chat.connect(paramsConnect, async (errorConnect: any) => {
                            if (errorConnect) {
                                console.log('Cannot connect to chat server: ', errorConnect);
                            } else {
                                setSDKInitialized(true);
                                setUserAuthorized(true);

                                const dialogParams = {
                                    type: 3,
                                    occupants_ids: [+courierChatDetails.id]
                                };
                                QB.chat.dialog.create(dialogParams, async (error: any, dialog: any) => {
                                    if (!error) {
                                        console.log('Dialog has been created: ', dialog);
                                        
                                        // Initiating the audio call
                                        const calleesIds = [+courierChatDetails.id];
                                        const sessionType = QB.webrtc.CallType.AUDIO; // Set to audio
                                        // const additionalOptions = {};
                                        const session = QB.webrtc.createNewSession(calleesIds, sessionType);
                                        setCallSession(session);

                                        const mediaParams = {
                                            audio: true,
                                            video: false, // Only audio
                                            options: {
                                                muted: false,
                                            },
                                        };

                                        session.getUserMedia(mediaParams, (error: any, stream: any) => {
                                            if (error) {
                                                console.error('Error getting user media:', error);
                                            } else {
                                                // Initiating the call
                                                session.call({}, (error: any) => {
                                                    if (error) {
                                                        console.error('Call error:', error);
                                                    } else {
                                                        console.log('Call initiated');
                                                    }
                                                });
                                            }
                                        });

                                        // Handle incoming calls
                                        QB.webrtc.onCallListener = function (incomingSession: any, extension: any) {
                                            incomingSession.getUserMedia(mediaParams, (error: any, stream: any) => {
                                                if (error) {
                                                    console.error('Error getting media for incoming call:', error);
                                                } else {
                                                    incomingSession.accept(extension); // Automatically accepting call
                                                }
                                            });
                                        };

                                        // Handle end call
                                        QB.webrtc.onStopCallListener = function (session: any, userId: any, extension: any) {
                                            console.log('Call ended');
                                            navigate('/calls'); // Redirect or handle call end
                                        };
                                    }
                                });
                            }
                        });
                    }
                });
            }).catch(e => console.log('init SDK has error: ', e));
        }
    }, []);

    const endCall = () => {
        if (callSession) {
            callSession.stop({});
        }
        navigate(-1);
    };

    return (
        <>
            <div className='w3-animate-left'>
                <div className="mx-auto mt-5 mb-3 d-flex align-items-center justify-content-center w3-circle" style={{ width: '150px', height: '150px' }}>
                    <img src={merchantOrder.photo ? getImage(merchantOrder.photo) : userPhoto} alt="" className='w-100 h-100 rounded-circle w3-image w3-animate-zoom' />
                </div>
                <h3 className='text-center fs-3 fw-bold mb-3'>{merchantOrder.fullname}</h3>
                <p className='fw-bold fs-6 my-0 text-center w3-light-gray w3-round-xxlarge mt-4 mx-auto py-3 px-5'
                    style={{ maxWidth: '150px' }}
                >Calling...</p>
                <div className='d-flex justify-content-around align-items-center mt-5'>
                    <div className='d-flex justify-content-center align-items-center me-4 w3-light-gray w3-circle' style={{ width: '70px', height: '70px' }}>
                        <i className="bi bi-mic-mute-fill fs-1"></i>
                    </div>
                    <div className='d-flex justify-content-center align-items-center ms-4 w3-light-gray w3-circle' style={{ width: '70px', height: '70px' }}>
                        <i className="bi bi-volume-up-fill fs-1"></i>
                    </div>
                </div>

                <div className='position-fixed bottom-0 start-50 translate-middle-x ' style={{marginBottom: '120px'}}>
                    <div onClick={endCall} className='d-flex justify-content-center align-items-center me-3 w3-circle brand negative' style={{ width: '90px', height: '90px' }}>
                        <img src="./images/telephone-down.png" alt="" width={40} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default VoiceCall;
