import React from "react";
import { Link, useNavigate } from "react-router-dom";
import UserContext from "../../../Helpers/UserContext";
import { BLOCKBUTTON, INPUT, SELECTOPTION, SELECT_DROPDOWN } from "../../../Components/Forms";
import { WITHDRAW_FUNDS } from "../../../GraphQL/Mutations";
import { useMutation } from "@apollo/client";
import Session from "../../../Helpers/Session";
import { Validate } from "../../../Helpers/Validate";
import NumberToMoney from "../../../Helpers/numberToMoney";
import { Loading } from "../../../Components/Loading";
import LottieAnimation from "../../../Components/LottieAnimation";
import SuccessAnime from "../../../assets/lottie/successful.json";
import { APIResponse } from "../../../Helpers/General";
import { fetchBanks, resolveBankAccount, createTransferRecipient, initiateTransfer } from "../../../Components/Paystack";
import uuid from "../../../Helpers/uuid";

const Withdraw: React.FC = () => {
    const myContext = React.useContext(UserContext);
    const user = myContext.user || Session.get('user');
    const [hideBalance, setHideBalance] = React.useState<boolean>(false);
    const [balance, setBalance] = React.useState<number>(user?.balance || 0.00);
    const [amountToWithdraw, setAmountToWithdraw] = React.useState<string>("");
    const [accountNumber, setAccountNumber] = React.useState<string>("");
    const [accountName, setAccountName] = React.useState<string>("");
    const [bankName, setBankName] = React.useState<string>("");
    const [bankCode, setBankCode] = React.useState<string>("");
    const [handleModal, setHandleModal] = React.useState<boolean>(false);
    const [banks, setBanks] = React.useState([])
    const navigate = useNavigate();

    const [withdrawFunds, { loading }] = useMutation(WITHDRAW_FUNDS, {
        onCompleted: (data) => {
            myContext.setUser({ ...user, balance: balance - parseFloat(amountToWithdraw) })
            Session.set("user", { ...user, balance: balance - parseFloat(amountToWithdraw) })
            if (data.withdrawFunds) {
                setHandleModal(true);
                setBalance(balance - parseFloat(amountToWithdraw));
                setAccountNumber("");
                setAccountName("");
                setBankName("");
            }
        },
        onError: (error) => {
            APIResponse(error)
            Session.showAlert({ str: error.message, type: 'error' });
        },
    });



    const toggleBalanceVisibility = () => {
        setHideBalance(!hideBalance);
    };

    const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAmountToWithdraw(e.target.value);
    };

    const isAmountValid = () => {
        const amount = parseFloat(amountToWithdraw);
        return amountToWithdraw === "" || (amount > 0 && amount <= balance);
    };

    const validateAccountNumber = () => {
        return accountNumber.length === 10;
    };
    const validAmountInput = () => {
        const amount = parseFloat(amountToWithdraw);
        return (amount > 0 && amount <= balance);
    };

    const handleWithdrawal = async () => {
        try {
            if (!isAmountValid()) {
                Session.showAlert({ str: 'Please enter a valid amount', type: 'warning' });
                return;
            }
            if (!validateAccountNumber()) {
                Session.showAlert({ str: 'Please enter a valid account number of 10-digits', type: 'warning' });
                return;
            }
            if (!bankName || !Validate.string(bankName)) {
                Session.showAlert({ str: 'Please enter your bank name', type: 'warning' });
                return;
            }
            if (!accountName || !Validate.string(accountName)) {
                Session.showAlert({ str: 'Please enter your account name', type: 'warning' });
                return;
            }
    
            // const recipientResponse = await createTransferRecipient({
            //     name: accountName,
            //     account_number: accountNumber,
            //     bank_code: bankCode,
            //     currency: 'NGN'
            // });
    
            // console.log("Create Recipient", recipientResponse);
            const reference = uuid();
    
            // const transferResponse = await initiateTransfer({
            //     source: 'balance',
            //     amount: +amountToWithdraw,
            //     recipient: recipientResponse.data.recipient_code,
            //     reason: "Courier Withdrawal",
            //     reference: reference
            // });
    
            // console.log("Initiate Transfer", transferResponse);
    
            await withdrawFunds({
                variables: {
                    amount: parseFloat(amountToWithdraw),
                    accountNumber: accountNumber,
                    accountName: accountName,
                    bankName: bankName,
                    reference: reference
                }
            });
    
            // setHandleModal(false);
            // navigate('/menu/wallet')
        } catch (error: any) {
            console.error(error);
            if (error.message.includes('creating recipient')) {
                Session.showAlert({ str: 'An error occurred while creating recipient', type: 'error' });
            } else if (error.message.includes('initiating transfer')) {
                Session.showAlert({ str: 'An error occurred while initiating transfer', type: 'error' });
            } else {
                Session.showAlert({ str: 'An error occurred while processing your request', type: 'error' });
            }
        }
    };
    

    const handleFetchingBanks = async () => {
        try {
            const result = await fetchBanks({ country: "nigeria" })
            setBanks(result);
        } catch (error) {
            Session.showAlert({ str: 'An error occurred while fetching banks', type: 'error' });
        }
    }

    React.useEffect(() => {
        handleFetchingBanks()
    }, [])
    const handleBankName = (e: string) => {
        let bankName: any = banks.filter((bank: any) => bank.name === e)[0];
        setBankName(bankName.name);
        setBankCode(bankName.code);
    }

    const verifyAccountName = () => {
        resolveBankAccount({ account_number: accountNumber, bank_code: bankCode })
            .then((response: any) => {
                const data = response.data;
                setAccountName(data.account_name);
            })
            .catch((error) => {
                Session.showAlert({ str: 'Your account details are incorrect.', type: 'error' });
            })
    };

    React.useEffect(() => {
        if (accountNumber.length !== 10 || bankCode.length < 2) return;
        verifyAccountName()
    }, [accountNumber, bankCode]);

    if (loading) {
        return <Loading fullPage={true} message="Withdrawal in progress..." />;
    }

    return (
        <>
            <div className="w3-animate-left">
                <div className="bg-primary w-100">
                    <div className='d-flex align-items-baseline justify-content-between pt-4 pb-1'>
                        <div>
                            <Link className="dark fs-3 ps-3" to="/wallet">
                                <i className="bi bi-arrow-left text-light fw-bolder"></i>
                            </Link>
                        </div>
                        <div className='w3-center fs-3 text-light d-flex justify-content-center'>
                            <h3 className='w3-center text-light mb-0'>Withdraw</h3>
                        </div>
                        <div className='me-3'></div>
                    </div>
                </div>
                <div className="mt-4 mb-3">
                    <div>
                        <p className="text-center mb-0 text-dark">Wallet Balance</p>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <h2 className="text-center text-dark fw-normal my-0">
                            {hideBalance ? "*".repeat(NumberToMoney(+balance.toFixed(2)).toString().length) : (<span>&#8358;{NumberToMoney(+balance.toFixed(2))}</span>)}
                        </h2>
                        <i
                            className={`bi ${hideBalance ? "bi-eye" : "bi-eye-slash"} ms-2 text-dark fs-2`}
                            onClick={toggleBalanceVisibility}
                            style={{ cursor: "pointer" }}
                        ></i>
                    </div>
                </div>
                <div className="container my-3">
                    <div className="px-4">
                        <p className="text-center mb-0 text-wrap">Kindly fill the form below to withdraw from your wallet to your bank instantly.</p>
                    </div>
                    <div className="mt-4">
                        <label>Enter the amount to withdraw</label>

                        <INPUT
                            type="number"
                            value={amountToWithdraw}
                            onChange={handleAmountChange}
                            pattern="[0-9]*"
                            placeholder="Enter amount to withdraw"
                        />
                        {
                            !isAmountValid() && (
                                <div className='text-error text-small mt-3 mb-4'>
                                    <i className="bi bi-exclamation-triangle-fill"></i>
                                    {balance === 0 ? (
                                        <span>You don't have enough balance in your wallet. Please deposit to continue.</span>
                                    ) : (
                                        <span>Insufficient balance, You can only withdraw up to <span>&#8358;{balance.toFixed(2)}</span></span>
                                    )}
                                </div>
                            )
                        }
                        <label>Enter Account Number</label>

                        <INPUT
                            type="text"
                            className="input"
                            value={accountNumber}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const value = e.target.value.replace(/\D/g, '')
                                setAccountNumber(value)
                            }}
                            pattern="\d{10}"
                            placeholder="Enter account number"
                            maxLength={10}
                        />
                        <label>Enter Bank Destination</label>

                        <SELECT_DROPDOWN 
                        showSearch={true} 
                        value={bankName}
                        placeholder="Select your bank"
                        items={(banks.map((bank: Record<string, any>) => bank.name))} 
                        onSelect={handleBankName} />

                        <label>Account name</label>
                        <INPUT
                            type="text"
                            value={accountName}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAccountName(e.target.value)}
                            placeholder="Account Name"
                            readOnly={true}
                        />

                        <div className="container position-fixed bottom-0 start-50 translate-middle-x w-100 d-flex justify-content-center pb-3 bg-light">
                            <BLOCKBUTTON
                                disabled={!accountName || !accountNumber || !bankCode || !amountToWithdraw || !validAmountInput() || !validateAccountNumber()}
                                className={"btn mt-4 btn-primary w-100"}
                                onClick={handleWithdrawal}
                            >
                                Withdraw now
                            </BLOCKBUTTON>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={'brand-modal w3-animate-bottom ' + (handleModal ? 'd-flex' : 'd-none')}
                onTouchMove={() => setHandleModal(false)}
            >
                <div
                    className='container brand-modal-content bg-light'
                >
                    <div className='w3-center'>
                        <LottieAnimation
                            animationData={SuccessAnime}
                            loop={true}
                            width={150}
                            height={150}
                            autoplay={true}
                        />
                    </div>
                    <div>
                        <h3 className="text-center my-0">Withdrawal Successful</h3>
                        <p className='text-center text-wrap text-muted'>
                            The sum of <span className='fw-bold text-primary'>&#8358;{`${NumberToMoney(+amountToWithdraw)}`}</span> has been withdrawn from your wallet.
                        </p>
                    </div>
                    <div className='d-flex justify-content-around align-items-center'>
                        <BLOCKBUTTON
                            className='btn btn-primary mt-4 mb-3'
                            onClick={() => {
                                setHandleModal(false)
                                navigate('/wallet')
                            }}
                        >
                            Continue
                        </BLOCKBUTTON>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Withdraw;
