import React from 'react';
import Menu from './Menu';
import UserContext from '../../Helpers/UserContext';
import Session from '../../Helpers/Session';
import { GoogleMap, Circle, DirectionsRenderer, Marker } from "@react-google-maps/api";
import UserCurrentLocation from "../../Helpers/UserCurrentLocation";
import { MapStyles } from '../../Helpers/GoogleMaps';
import { GO_OFFLINE, GO_ONLINE, COURIER_ACCEPT_ORDER, COURIER_DECLINE_ORDER, UPDATE_ORDER_STATUS } from '../../GraphQL/Mutations';
import { useMutation, useLazyQuery } from '@apollo/client';
import { GET_COURIER_PENDING_ORDERS, NOTICE_ORDER_STATUS_AND_LOCATION, CURRENT_USER_COURIER } from '../../GraphQL/Queries';
import { APIResponse } from '../../Helpers/General';
import { getGeocode, getLatLng } from "use-places-autocomplete";
import NumberToMoney from '../../Helpers/numberToMoney';
import userPhoto from "../../assets/images/photo.png";
import { BLOCKBUTTON, BUTTON } from '../../Components/Forms';
import ConfirmOrder from './Dashboard/ConfirmOrder';
import CancelOrder from './Dashboard/CancelOrder';
import { useNavigate } from 'react-router-dom';
import { getImage } from '../../Helpers/FileHandling';
import MarkerIcon from "../../assets/images/location-pin.png";
import Motorbike from "../../assets/images/motorbike.png";
import { Loading } from '../../Components/Loading';

interface Location {
    lat: number;
    lng: number;
}

const Dashboard = () => {
    const myContext = React.useContext(UserContext);
    const user = myContext.user || Session.get('user');
    const [userLocation, setUserLocation] = React.useState<Location | null>(null);
    const [map, setMap] = React.useState<google.maps.Map | null>(null);
    const [isOnline, setIsOnline] = React.useState<number>(user.courierDetails.is_online ? 1 : 0);
    const [currentAddress, setCurrentAddress] = React.useState<string>('');
    const [pendingOrders, setPendingOrders] = React.useState<Record<string, any>[]>([]);
    const [previousLocation, setPreviousLocation] = React.useState<Location | null>(null);
    const [viewMoreModal, setViewMoreModal] = React.useState(false);
    const [step, setStep] = React.useState(Session.get('step') || 0);
    const [moreOrderDetails, setMoreOrderDetails] = React.useState(Session.get('selectedOrder') || null);
    const [pickUpdirections, setPickUpDirection] = React.useState<google.maps.DirectionsResult | null>(null);
    const [dropOffdirections, setDropOffDirection] = React.useState<google.maps.DirectionsResult | null>(null);
    const [time, setTime] = React.useState(0);
    const [isWaiting, setIsWaiting] = React.useState(false);
    const [distance, setDistance] = React.useState("");
    const [duration, setDuration] = React.useState("");
    const [previousDirectionsRequest, setPreviousDirectionsRequest] = React.useState<{ origin: string, destination: string } | null>(null);
    const [pickupLocation, setPickupLocation] = React.useState<google.maps.LatLngLiteral | null>(null);
    const [dropOffLocation, setDropOffLocation] = React.useState<google.maps.LatLngLiteral | null>(null);
    const [directions, setDirections] = React.useState<google.maps.DirectionsResult | null>(null);
    const navigate = useNavigate();
    const [orderStatus, setOrderStatus] = React.useState<Record<string, any>>({});
    const [prevOrderStatus, setPrevOrderStatus] = React.useState("");
    const [verifyStatusFromMerchant, setVerifyStatusFromMerchant] = React.useState(false);
    const [voiceCallOption, setVoiceCallOption] = React.useState(false);
    const [locationUpdateNotifier, setLocationUpdateNotifier] = React.useState(false);
    const [showNotificationIcon, setShowNotificationIcon] = React.useState(true);
    const [newOrderNumber, setNewOrderNumber] = React.useState(0);
    const [prevOrderNumber, setPrevOrderNumber] = React.useState(0);

    const isCloseTo = (loc1: google.maps.LatLngLiteral, loc2: google.maps.LatLngLiteral, threshold = 0.05) => {
        const latDiff = Math.abs(loc1.lat - loc2.lat);
        const lngDiff = Math.abs(loc1.lng - loc2.lng);
        return latDiff < threshold && lngDiff < threshold;
    };

    const [refreshUser] = useLazyQuery(CURRENT_USER_COURIER, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            myContext.setUser(data.currentUserCourier);
            Session.setCookie('user', JSON.stringify(data.currentUserCourier));
            Session.set("user", data.currentUserCourier);
        }
    });

    React.useEffect(() => {
        if (!showNotificationIcon) return;
        // Set a timeout to hide the element after 3 seconds
        const timeoutId = setTimeout(() => {
            setShowNotificationIcon(false);
        }, 3000);

        // Cleanup the timeout if the component unmounts
        return () => clearTimeout(timeoutId);
    }, [showNotificationIcon]);


    const [goOnline] = useMutation(GO_ONLINE, {
        onCompleted: () => {
            refreshUser();
            setIsOnline(1);
        },
        onError: (error) => {
            APIResponse(error);
            Session.showAlert({ str: error.message, type: 'error' });
            setIsOnline(0);
        }
    });

    const handleStatus = (newStatus: string) => {
        if (newStatus === "Cancelled") {
            Session.showAlert({ str: "The order has been cancelled", type: 'info' });
            Session.remove("selectedCourier");
            Session.remove("package_details");
            Session.remove("step");
            setMoreOrderDetails(null);
            Session.remove("selectedOrder");
            fetchPendingOrders();
            setStep(0);
            navigate("/")
            navigate("/wallet");
        } else if (newStatus === "Updated") {
            Session.showAlert({ str: "Delivery Address Updated", type: 'info' });
        }
    }

    const [noticeOrderStatusAndLocation] = useLazyQuery(NOTICE_ORDER_STATUS_AND_LOCATION, {
        fetchPolicy: "network-only",
        variables: { orderId: moreOrderDetails?.id },
        onCompleted(data) {
            const newStatus = data.noticeOrderStatusAndLocation.status;
            const newReceiverAddress = data.noticeOrderStatusAndLocation.receiver_address;

            if (newStatus === "Cancelled") {
                handleStatus(newStatus);
            } else if (newReceiverAddress !== moreOrderDetails?.receiver_address) {
                handleStatus("Updated");
                setMoreOrderDetails({ ...moreOrderDetails, receiver_address: newReceiverAddress, distance: data.noticeOrderStatusAndLocation.distance, duration: data.noticeOrderStatusAndLocation.duration, amount: data.noticeOrderStatusAndLocation.amount });
                Session.set("selectedOrder", moreOrderDetails);
            }
        },
        onError(error) {
            APIResponse(error);
        },
    });

    const updateOrderStatusFromMerchant = React.useCallback(async () => {
        try {
            await noticeOrderStatusAndLocation();
        } catch (error) {
            console.error(error);
        }
    }, [noticeOrderStatusAndLocation]);

    React.useEffect(() => {
        if (!verifyStatusFromMerchant) return;
        updateOrderStatusFromMerchant();
        const intervalId = setInterval(updateOrderStatusFromMerchant, 5000);
        return () => clearInterval(intervalId);
    }, [updateOrderStatusFromMerchant, orderStatus, verifyStatusFromMerchant, moreOrderDetails]);

    const [goOffline] = useMutation(GO_OFFLINE, {

        onCompleted: () => {
            refreshUser();
            setIsOnline(0);
        },
        onError: (error) => {
            APIResponse(error);
            Session.showAlert({ str: error.message, type: 'error' });
            setIsOnline(0);
        }
    });



    const [fetchPendingOrders] = useLazyQuery(GET_COURIER_PENDING_ORDERS, {
        fetchPolicy: "network-only",
        onCompleted(data) {
            if (data.getCourierPendingOrders) {
                const newOrderNumber = data.getCourierPendingOrders.length;
                if (newOrderNumber > prevOrderNumber) {
                    setPrevOrderNumber(newOrderNumber);
                    Session.showAlert({ str: "New Pending Order", type: "info" });
                    // Debounce the alert to prevent multiple notifications
                    setShowNotificationIcon(true);
                } else {
                    setShowNotificationIcon(false);
                }
                setPendingOrders(data.getCourierPendingOrders);
                setNewOrderNumber(newOrderNumber);
            }
        },
        onError(error) {
            APIResponse(error);
            Session.showAlert({ str: error.message, type: 'error' });
            return;
        },
    });


    React.useEffect(() => {
        if (step < 2 && isOnline === 1) {
            fetchPendingOrders();
            const intervalId = setInterval(fetchPendingOrders, 5000);
            return () => clearInterval(intervalId);
        }
    }, [fetchPendingOrders, isOnline, step]);

    React.useEffect(() => {
        if (moreOrderDetails && moreOrderDetails.status === "Cancelled") {
            noticeOrderStatusAndLocation();
        }
    }, [moreOrderDetails, noticeOrderStatusAndLocation]);

    const fetchPickUpDirections = (pickup: google.maps.LatLngLiteral) => {
        if (!userLocation) return;

        const service = new google.maps.DirectionsService();
        service.route(
            {
                origin: { lat: userLocation.lat, lng: userLocation.lng },
                destination: pickup,
                travelMode: google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
                if (status === "OK" && result) {
                    setPickUpDirection(result);
                    setDuration(result?.routes[0]?.legs[0]?.duration?.text || "calculating...");
                    setDistance(result?.routes[0]?.legs[0]?.distance?.text || "calculating...");

                    // Fetch drop-off directions when user reaches pick-up location
                    if (result.routes[0].legs[0].end_location.lat() === pickup.lat && result.routes[0].legs[0].end_location.lng() === pickup.lng && dropOffLocation) {
                        fetchDropOffDirections(dropOffLocation);
                    }
                }
            }
        );
    };

    const fetchDropOffDirections = (dropOff: google.maps.LatLngLiteral) => {
        if (!pickupLocation) return;

        const service = new google.maps.DirectionsService();
        service.route(
            {
                origin: pickupLocation,
                destination: dropOff,
                travelMode: google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
                if (status === "OK" && result) {
                    setDropOffDirection(result);
                }
            }
        );
    };

    const handlePickUpDirection = async (pickupAddress: string, dropoffAddress: string) => {
        try {
            const pickupResults = await getGeocode({ address: pickupAddress });
            if (pickupResults.length === 0) {
                throw new Error("No results found for pickup address");
            }
            const { lat, lng } = await getLatLng(pickupResults[0]);
            setPickupLocation({ lat, lng });
            fetchPickUpDirections({ lat, lng });
            map?.panTo({ lat, lng });

            const dropoffResults = await getGeocode({ address: dropoffAddress });
            if (dropoffResults.length === 0) {
                throw new Error("No results found for dropoff address");
            }
            const { lat: dropoffLat, lng: dropoffLng } = await getLatLng(dropoffResults[0]);
            setDropOffLocation({ lat: dropoffLat, lng: dropoffLng });
        } catch (error) {
            console.error("Error handling pickup direction:", error);
        }
    };

    const [courierAcceptOrder] = useMutation(COURIER_ACCEPT_ORDER, {
        onCompleted: async () => {
            handleStep(3);
            if (map && moreOrderDetails) {
                handlePickUpDirection(currentAddress, moreOrderDetails.pickup_address);
            }
        },
        onError: (error) => {
            APIResponse(error);
            Session.showAlert({ str: error.message, type: 'error' });
        }
    });

    const [courierDeclineOrder] = useMutation(COURIER_DECLINE_ORDER, {
        onCompleted: () => {
            setMoreOrderDetails(null);
            Session.remove("selectedOrder");
            fetchPendingOrders();
            setStep(0);
            navigate("/")
        },
        onError: (error) => {
            APIResponse(error);
            Session.showAlert({ str: error.message, type: 'error' });
        }
    });

    const [updateOrderStatus] = useMutation(UPDATE_ORDER_STATUS, {
        onCompleted: () => {
            handleStep(step + 1);
        },
        onError: (error) => {
            APIResponse(error);
            Session.showAlert({ str: error.message, type: 'error' });
        }
    });

    React.useEffect(() => {
        fetchPendingOrders();
        const savedStep = Session.get("step");
        const savedOrder = Session.get("selectedOrder");
        if (savedStep && savedStep >= 3 && savedStep <= 7 && savedOrder) {
            setStep(savedStep);
            setMoreOrderDetails(savedOrder);
        }
    }, []);

    React.useEffect(() => {
        if (moreOrderDetails) {
            Session.set("selectedOrder", moreOrderDetails);
        }
    }, [moreOrderDetails]);

    const handleStep = (newStep: number) => {
        Session.set("step", newStep);
        setStep(newStep);
    };

    const updateLocation = React.useCallback(async () => {
        try {
            const location = await UserCurrentLocation();
            setUserLocation(location);
            if (map && location) {
                map.panTo({ lat: location.lat, lng: location.lng });
            }
        } catch (error) {
            console.error("Error getting user location:", error);
        }
    }, [map]);

    React.useEffect(() => {
        updateLocation();
        const intervalId = setInterval(updateLocation, 20000);
        return () => clearInterval(intervalId);
    }, [updateLocation]);


    React.useEffect(() => {
        let interval: NodeJS.Timeout;
        if (isWaiting) {
            interval = setInterval(() => {
                setTime((prevTime) => prevTime + 1);
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isWaiting]);

    const formatTime = (seconds: number): string => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');

        return `${formattedMinutes}:${formattedSeconds}`;
    };

    const fetchDirections = async () => {
        if (!userLocation || !pickupLocation) return;

        let origin: google.maps.LatLngLiteral;
        let destination: google.maps.LatLngLiteral;

        // Set the direction origin and destination based on courier's proximity to pickup
        if (isCloseTo(userLocation, pickupLocation)) {
            if (!dropOffLocation) return;  // Drop-off location must be set for this stage
            origin = pickupLocation;
            destination = dropOffLocation;
        } else {
            origin = userLocation;
            destination = pickupLocation;
        }

        // Get directions and set to state
        const directionsService = new google.maps.DirectionsService();
        try {
            const result = await directionsService.route({
                origin,
                destination,
                travelMode: google.maps.TravelMode.DRIVING,
            });
            setDirections(result);
        } catch (error) {
            console.error("Error fetching directions:", error);
        }
    };

    React.useEffect(() => {
        fetchDirections();
    }, [userLocation, pickupLocation, dropOffLocation]);

    const getDirections = async (origin: google.maps.LatLngLiteral, destination: google.maps.LatLngLiteral): Promise<google.maps.DirectionsResult | undefined> => {
        const directionsService = new google.maps.DirectionsService();
        try {
            const response = await directionsService.route({
                origin,
                destination,
                travelMode: google.maps.TravelMode.DRIVING,
            });
            return response;
        } catch (error) {
            console.error(error);
            return undefined;
        }
    };

    const center: google.maps.LatLngLiteral = { lat: userLocation?.lat || 0, lng: userLocation?.lng || 0 };

    const getLocationString = async ({ latitude, longitude }: { latitude: number, longitude: number }) => {
        if (!latitude || !longitude || longitude === 0 || latitude === 0) return;
        const results = await getGeocode({ location: { lat: latitude, lng: longitude } });
        if (results[0]) {
            const address = results[0].formatted_address;
            setCurrentAddress(address);
        }
    };

    React.useEffect(() => {
        if (!userLocation || userLocation === previousLocation || pendingOrders.length > 0) return;
        if (userLocation && userLocation !== previousLocation) {
            getLocationString({ latitude: userLocation.lat, longitude: userLocation.lng });
            setPreviousLocation(userLocation);
        }
    }, [userLocation, previousLocation, pendingOrders.length]);

    React.useEffect(() => {
        if (step !== 3) return;
        handlePickUpDirection(currentAddress, moreOrderDetails.pickup_address);
    }, [step, currentAddress, moreOrderDetails]);

    React.useEffect(() => {
        if (step !== 4 && step !== 5 && step !== 6) return;
        handlePickUpDirection(currentAddress, moreOrderDetails.receiver_address);
    }, [step, currentAddress, moreOrderDetails]);

    const callUsingMobile = () => {
        if (moreOrderDetails && moreOrderDetails.merchantDetails.phone) {
            const phoneNumber = moreOrderDetails.merchantDetails.phone.replace(/\D/g, ''); // Remove non-digit characters
            const telUrl = `tel:${phoneNumber}`;

            // Check if the device supports tel: protocol
            const supportsTel = document.createElement('a');
            supportsTel.href = telUrl;

            if (supportsTel.protocol === 'tel:') {
                // Open the phone app with the number
                window.location.href = telUrl;
            } else {
                // Fallback for devices that don't support tel: protocol
                console.warn('This device does not support making phone calls.');
                // You might want to show an alert or message to the user here
                alert('Unable to initiate call. Please dial ' + phoneNumber + ' manually.');
            }
        } else {
            console.error('Phone number is not available');
            // You might want to show an alert or message to the user here
            alert('Phone number is not available for this merchant.');
        }
    };

    if(pendingOrders.length < 1 && step === 0 && !currentAddress){
        return <Loading />
    }
    return (
        <div className='w3-animate-left'>
            <div className="position-absolute w-100 h-100">
                <GoogleMap
                    mapContainerStyle={{ height: "100svh", width: "100svw" }}
                    center={userLocation || pickupLocation || { lat: 0, lng: 0 }}
                    zoom={12}
                    onLoad={(map: google.maps.Map) => setMap(map)}
                    options={{
                        styles: MapStyles,
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                        zoomControl: false,
                        gestureHandling: "greedy",
                    }}
                >
                    {/* Render markers */}
                    {userLocation && isOnline === 1 && (
                        <>
                            <Marker position={userLocation} icon={MarkerIcon} />
                            <Circle center={userLocation} radius={5000} options={closeOptions} />
                        </>
                    )}
                    {pickupLocation && (
                        <Marker position={pickupLocation} icon={Motorbike} />
                    )}
                    {dropOffLocation && (
                        <Marker position={dropOffLocation} icon={MarkerIcon} />
                    )}
                    {/* Render directions */}
                    {directions && (
                        <DirectionsRenderer
                            directions={directions}
                            options={{
                                suppressMarkers: true,
                                polylineOptions: {
                                    strokeColor: "#FF6600",
                                    strokeWeight: 5,
                                },
                            }}
                        />
                    )}
                </GoogleMap>
            </div>

            <div className="position-absolute w-100 h-100" style={{ zIndex: 1 }}>
                <div className='d-flex align-items-center justify-content-center'>
                    {/* <div className=' mt-3 me-3'>
                    </div> */}
                    {step <= 2 ? (
                        <div className=" d-flex justify-content-center align-items-center p-2 bg-light shadow-none w3-round-xxlarge mt-3 mx-auto d-block w-100" style={{ maxWidth: "250px" }}>
                            <div className={"w-50 py-3 fw-bold w3-round-xxlarge text-center " + (isOnline === 1 && "bg-success text-light")}
                                onClick={() => {
                                    goOnline();
                                }}
                            >Online</div>
                            <div className={"w-50 py-3 fw-bold w3-round-xxlarge text-center shadow-none " + (isOnline !== 1 && "brand negative")}
                                onClick={() => {
                                    goOffline();
                                }}
                            >Offline</div>
                        </div>
                    ) : (
                        <div className='w-75'>
                        </div>
                    )}
                    {(step <= 2 && showNotificationIcon) && (
                        <BUTTON className={' bg-light w3-round-xlarge mt-3 px-3 py-2 me-2 fw-bold '}>
                            <i className='bi bi-bell fs-3'></i>
                        </BUTTON>
                    )}
                    {step >= 2 && step < 4 && (
                        <BUTTON className={' bg-light w3-round-xlarge mt-3 px-3 py-2 me-2 fw-bold '}>

                            <span className='text-negative text-nowrap'
                                onClick={() => setStep(8)}
                            >Cancel Order</span>
                        </BUTTON>
                    )}
                </div>
            </div>

            {(pendingOrders.length < 1 || isOnline === 0) && step === 0 ? (
                <>
                    <div>
                        <div className={'brand-modal-map w3-animate-bottom'}>
                            <div className='w-100 brand-modal-content bg-light'>
                                <div className='px-2 pt-3' style={{ marginBottom: "75px" }}>
                                    <h3 className='fw-bold fs-4 my-0 pb-3'>{isOnline ? "Searching for Delivery Requests" : "You're currently Offline"}</h3>
                                    <p className='my-0 mb-2'>My Location</p>
                                    <div className='d-flex align-items-center pb-3'>
                                        <i className="bi bi-geo-alt-fill text-primary fs-3"></i>
                                        <div className='ms-3 fw-bold px-2 py-1 border border-1 border-muted w3-round-large'>{currentAddress}</div>
                                    </div>
                                </div>
                            </div>
                            <Menu />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className={'brand-modal-map w3-animate-bottom'}>
                        <div className='w-100 brand-modal-content bg-light'>
                            <div className='px-1 pt-2' style={{ marginBottom: "75px", maxHeight: "75vh", overflowY: "auto" }}>
                                {
                                    (pendingOrders && !moreOrderDetails && isOnline === 1) && pendingOrders.map((order: Record<string, any>, index: number) => (
                                        <div key={order.id} className='px-3 py-2 bg-light mb-2 shadow-none w3-round-large w3-light-gray w3-animate-bottom'
                                            onClick={() => {
                                                Session.set("selectedOrder", order);
                                                setMoreOrderDetails(Session.get("selectedOrder"));
                                                setStep(2);
                                            }}
                                        >
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='rounded-circle d-flex align-items-center justify-content-center'
                                                        style={{ width: '50px', height: '50px' }}
                                                    >
                                                        <img src={order.merchantDetails.photo ? getImage(order.merchantDetails.photo) : userPhoto} alt={order.sender_name || "Unknown"} className="w-100 h-100 rounded-circle w3-image w3-animate-zoom w3-hover-shadow" />
                                                    </div>
                                                    <p className='fw-bold my-0 fs-5 ms-2'>{order.sender_name ? order.sender_name.split(' ')[0] : "Unknown"}</p>
                                                </div>
                                                <p className='my-0'>Distance - {(order.distance / 1000).toFixed(2)}&nbsp;Km</p>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.75" height="21.75" fill="currentColor" className="bi bi-crosshair2 text-muted" viewBox="0 0 16 16">
                                                        <path d="M8 0a.5.5 0 0 1 .5.5v.518A7 7 0 0 1 14.982 7.5h.518a.5.5 0 0 1 0 1h-.518A7 7 0 0 1 8.5 14.982v.518a.5.5 0 0 1-1 0v-.518A7 7 0 0 1 1.018 8.5H.5a.5.5 0 0 1 0-1h.518A7 7 0 0 1 7.5 1.018V.5A.5.5 0 0 1 8 0m-.5 2.02A6 6 0 0 0 2.02 7.5h1.005A5 5 0 0 1 7.5 3.025zm1 1.005A5 5 0 0 1 12.975 7.5h1.005A6 6 0 0 0 8.5 2.02zM12.975 8.5A5 5 0 0 1 8.5 12.975v1.005a6 6 0 0 0 5.48-5.48zM7.5 12.975A5 5 0 0 1 3.025 8.5H2.02a6 6 0 0 0 5.48 5.48zM10 8a2 2 0 1 0-4 0 2 2 0 0 0 4 0" />
                                                    </svg>
                                                    <p className="mb-0 ps-2 text-muted fs-6">Pick Up: <span className="text-dark fw-bold">{order.pickup_address.split(' ').slice(0, 3).join(' ')}</span></p>
                                                </div>
                                                <div className="d-flex justify-content-center align-items-center send-container me-3">
                                                    <p className="mb-0 text-muted text-nowrap">Delivery fee</p>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <i className="bi bi-geo-alt-fill text-primary p-0 m-0 fs-3"></i>
                                                    <p className="mb-0 ps-2 text-muted fs-6">Drop Off: <span className="text-dark fw-bold">{order.receiver_address.split(' ').slice(0, 3).join(' ')}</span></p>
                                                </div>
                                                <div>
                                                    <p className="mb-0 fs-5 fw-bold">&#8358;&nbsp;{NumberToMoney(order.amount)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <Menu />
                    </div>

                    {
                        moreOrderDetails && (
                            <>
                                {step === 2 && (
                                    <>
                                        <div className={'brand-modal-map w3-animate-bottom'}>
                                            <div className='w-100 brand-modal-content bg-light'>
                                                <div className='px-1 pt-2 pb-4'>
                                                    <div className='w3-animate-bottom'>
                                                        <div className='d-flex justify-content-between align-items-center mb-3'>
                                                            <div className='d-flex align-items-center ms-2'>
                                                                <div className='rounded-circle d-flex align-items-center justify-content-center'
                                                                    style={{ width: '50px', height: '50px' }}
                                                                >
                                                                    <img src={moreOrderDetails.merchantDetails.photo ? getImage(moreOrderDetails.merchantDetails.photo) : userPhoto} alt={moreOrderDetails.sender_name || "Unknown"} className="w-100 h-100 rounded-circle w3-image w3-animate-zoom w3-hover-shadow" />
                                                                </div>
                                                                <div>
                                                                    <p className='fw-bold my-0 fs-6 ms-2'>{moreOrderDetails.sender_name ? moreOrderDetails.sender_name.split(' ')[0] : "Unknown"}</p>
                                                                    <p className=' my-0 fs-6 ms-2'>Distance - {(moreOrderDetails.distance / 1000).toFixed(2)}Km</p>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex align-items-end flex-column justify-content-end me-2'>
                                                                <p className=' my-0 fs-6 ms-2'>Delivery fee</p>
                                                                <p className='fw-bold my-0 fs-6 ms-2'>&#8358;&nbsp;{NumberToMoney(moreOrderDetails.amount)}</p>
                                                            </div>
                                                        </div>
                                                        <div className="progress mb-3" style={{ height: "4px", borderRadius: "2px" }}>
                                                            <div className="progress-bar bg-primary progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={75} aria-valuemin={0} aria-valuemax={100} style={{ width: "100%", height: "4px" }}></div>
                                                        </div>
                                                        <div className="d-flex align-items-center mb-2 ms-2">
                                                            <div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="21.75" height="21.75" fill="currentColor" className="bi bi-crosshair2 text-muted" viewBox="0 0 16 16">
                                                                    <path d="M8 0a.5.5 0 0 1 .5.5v.518A7 7 0 0 1 14.982 7.5h.518a.5.5 0 0 1 0 1h-.518A7 7 0 0 1 8.5 14.982v.518a.5.5 0 0 1-1 0v-.518A7 7 0 0 1 1.018 8.5H.5a.5.5 0 0 1 0-1h.518A7 7 0 0 1 7.5 1.018V.5A.5.5 0 0 1 8 0m-.5 2.02A6 6 0 0 0 2.02 7.5h1.005A5 5 0 0 1 7.5 3.025zm1 1.005A5 5 0 0 1 12.975 7.5h1.005A6 6 0 0 0 8.5 2.02zM12.975 8.5A5 5 0 0 1 8.5 12.975v1.005a6 6 0 0 0 5.48-5.48zM7.5 12.975A5 5 0 0 1 3.025 8.5H2.02a6 6 0 0 0 5.48 5.48zM10 8a2 2 0 1 0-4 0 2 2 0 0 0 4 0" />
                                                                </svg>
                                                            </div>

                                                            <p className="mb-0 ms-2 text-muted fs-6"><span className="text-dark fw-bold">{moreOrderDetails.pickup_address}</span></p>
                                                        </div>
                                                        <div className="d-flex align-items-center mb-2 ms-2">
                                                            <i className="bi bi-geo-alt-fill text-primary p-0 m-0 fs-3"></i>
                                                            <p className="mb-0 ms-2 text-muted fs-6"><span className="text-dark fw-bold">{moreOrderDetails.receiver_address}</span></p>
                                                        </div>

                                                        <p className="mb-0 ps-2 text-muted fs-6 ms-2">Package details</p>
                                                        <div className='d-flex justify-content-between align-items-center w-100 px-2 py-3 w3-light-gray w3-round-large mt-2'>
                                                            <p className="mb-0 ps-2 text-muted fs-6 fw-bold">{moreOrderDetails.package_name}</p>
                                                            <p className="mb-0 ps-2 text-primary fs-6 fw-bold" role='button'
                                                                onClick={() => setViewMoreModal(true)}
                                                            >View more</p>
                                                        </div>

                                                        <div className='d-flex align-items-center justify-content-between px-2'>
                                                            <BUTTON
                                                                className={"border border-2 border-primary text-primary w-50"}
                                                                style={{ width: "120px" }}
                                                                onClick={() => {
                                                                    courierDeclineOrder({
                                                                        variables: {
                                                                            orderId: moreOrderDetails.id
                                                                        }
                                                                    })
                                                                }}
                                                            >
                                                                Decline
                                                            </BUTTON>
                                                            <div className='w-25'></div>
                                                            <BUTTON
                                                                className={"btn-primary w-50"}
                                                                style={{ width: "120px" }}
                                                                onClick={async () => {
                                                                    courierAcceptOrder({
                                                                        variables: {
                                                                            orderId: moreOrderDetails.id
                                                                        }
                                                                    });
                                                                    setVerifyStatusFromMerchant(true)
                                                                }}
                                                            >
                                                                Accept
                                                            </BUTTON>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <Menu /> */}
                                        </div>

                                    </>
                                )}

                                {step === 3 && (
                                    <>
                                        <div className={'brand-modal-map w3-animate-bottom'}>
                                            <div className='w-100 brand-modal-content w3-sand'>
                                                <div className='text-center pt-2 pb-2 fw-bold'><i className="bi bi-bell pe-2"></i>Driving to pickup location</div>
                                                <div className='container px-2 pt-3 bg-light pb-4' >
                                                    <div className='w3-animate-bottom'>

                                                        <div className='d-flex justify-content-between align-items-center mb-3'>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='rounded-circle d-flex align-items-center justify-content-center'
                                                                    style={{ width: '50px', height: '50px' }}
                                                                >
                                                                    <img src={moreOrderDetails.merchantDetails.photo ? getImage(moreOrderDetails.merchantDetails.photo) : userPhoto} alt={moreOrderDetails.sender_name || "Unknown"} className="w-100 h-100 rounded-circle w3-image w3-animate-zoom w3-hover-shadow" />
                                                                </div>
                                                                <div>
                                                                    <p className='fw-bold my-0 fs-6 ms-2'>{moreOrderDetails.sender_name ? moreOrderDetails.sender_name.split(' ')[0] : "Unknown"}</p>
                                                                    <p className=' my-0 fs-6 ms-2'>Distance - {distance}</p>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex align-items-center '>
                                                                <div className='w3-light-gray w3-circle d-flex justify-content-center align-items-center p-3 mx-2'
                                                                    style={{ width: '40px', height: '40px' }}
                                                                    onClick={() => {
                                                                        navigate("/chat-with-merchant")
                                                                    }}
                                                                >
                                                                    <i className="bi bi-chat-right-text text-dark fs-3 "></i>
                                                                </div>
                                                                <div className='w3-light-gray w3-circle d-flex justify-content-center align-items-center p-3 ms-2'
                                                                    style={{ width: '40px', height: '40px' }}
                                                                    onClick={() => {
                                                                        setVoiceCallOption(true)
                                                                    }}
                                                                ><i className="bi bi-telephone-fill text-dark fs-3"></i></div>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <div>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.75" height="21.75" fill="currentColor" className="bi bi-crosshair2 text-muted" viewBox="0 0 16 16">
                                                                        <path d="M8 0a.5.5 0 0 1 .5.5v.518A7 7 0 0 1 14.982 7.5h.518a.5.5 0 0 1 0 1h-.518A7 7 0 0 1 8.5 14.982v.518a.5.5 0 0 1-1 0v-.518A7 7 0 0 1 1.018 8.5H.5a.5.5 0 0 1 0-1h.518A7 7 0 0 1 7.5 1.018V.5A.5.5 0 0 1 8 0m-.5 2.02A6 6 0 0 0 2.02 7.5h1.005A5 5 0 0 1 7.5 3.025zm1 1.005A5 5 0 0 1 12.975 7.5h1.005A6 6 0 0 0 8.5 2.02zM12.975 8.5A5 5 0 0 1 8.5 12.975v1.005a6 6 0 0 0 5.48-5.48zM7.5 12.975A5 5 0 0 1 3.025 8.5H2.02a6 6 0 0 0 5.48 5.48zM10 8a2 2 0 1 0-4 0 2 2 0 0 0 4 0" />
                                                                    </svg>
                                                                </div>
                                                                <p className="mb-0 ms-2 text-muted fs-6"><span className="text-dark fw-bold">{moreOrderDetails.pickup_address.split(' ').slice(0, 3).join(" ")}</span></p>
                                                            </div>
                                                            <p className=' my-0 fs-6 ms-2'>Delivery fee</p>
                                                        </div>

                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <i className="bi bi-geo-alt-fill text-primary p-0 m-0 fs-3"></i>
                                                                <p className="mb-0 ms-2 text-muted fs-6"><span className="text-dark fw-bold">{moreOrderDetails.receiver_address.split(' ').slice(0, 3).join(" ")}</span></p>
                                                            </div>
                                                            <p className='fw-bold my-0 fs-6 ms-2'>&#8358;&nbsp;{NumberToMoney(moreOrderDetails.amount)}</p>
                                                        </div>

                                                        <p className="mb-0 ps-2 text-muted fs-6 ">Package details</p>
                                                        <div className='d-flex justify-content-between align-items-center w-100 px-2 py-3 w3-light-gray w3-round-large mt-2'>
                                                            <p className="mb-0 ps-2 text-muted fs-6 fw-bold">{moreOrderDetails.package_name}</p>
                                                            <p className="mb-0 ps-2 text-primary fs-6 fw-bold" role='button'
                                                                onClick={() => setViewMoreModal(true)}
                                                            >View more</p>
                                                        </div>
                                                        <div>
                                                            <small className='text-muted'>Once you arrive at the pickup location, please click on the "Waiting" button.</small>
                                                        </div>
                                                        <div className='d-flex align-items-center justify-content-between'>

                                                            <BLOCKBUTTON
                                                                className={"btn-primary mt-1"}
                                                                onClick={() => {
                                                                    updateOrderStatus({ variables: { orderId: moreOrderDetails.id, status: 'Pickup_address' } })
                                                                    setIsWaiting(true);
                                                                    setVerifyStatusFromMerchant(true);
                                                                }}
                                                            >
                                                                Waiting
                                                            </BLOCKBUTTON>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <Menu /> */}
                                        </div>
                                    </>
                                )}
                                {step === 4 && (
                                    <>
                                        <div className={'brand-modal-map w3-animate-bottom'}>
                                            <div className='w-100 brand-modal-content w3-sand'>
                                                <div className='text-center pt-2 pb-2 fw-bold'><i className="bi bi-bell pe-2"></i>Waiting - <span className='text-primary fw-bold'>{formatTime(time)}</span></div>
                                                <div className='container px-2 pt-3 bg-light pb-4' >
                                                    <div className='w3-animate-bottom'>

                                                        <div className='d-flex justify-content-between align-items-center mb-3'>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='rounded-circle d-flex align-items-center justify-content-center'
                                                                    style={{ width: '50px', height: '50px' }}
                                                                >
                                                                    <img src={moreOrderDetails.merchantDetails.photo ? getImage(moreOrderDetails.merchantDetails.photo) : userPhoto} alt={moreOrderDetails.sender_name || "Unknown"} className="w-100 h-100 rounded-circle w3-image w3-animate-zoom w3-hover-shadow" />
                                                                </div>
                                                                <div>
                                                                    <p className='fw-bold my-0 fs-6 ms-2'>{moreOrderDetails.sender_name ? moreOrderDetails.sender_name.split(' ')[0] : "Unknown"}</p>
                                                                    <p className=' my-0 fs-6 ms-2'>Distance - {distance}</p>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex align-items-center '>
                                                                <div className='w3-light-gray w3-circle d-flex justify-content-center align-items-center p-3 mx-2'
                                                                    style={{ width: '40px', height: '40px' }}
                                                                    onClick={() => {
                                                                        navigate("/chat-with-merchant")
                                                                    }}
                                                                ><i className="bi bi-chat-right-text text-dark fs-3 "></i></div>
                                                                <div className='w3-light-gray w3-circle d-flex justify-content-center align-items-center p-3 ms-2'
                                                                    style={{ width: '40px', height: '40px' }}
                                                                    onClick={() => {
                                                                        setVoiceCallOption(true)
                                                                    }}
                                                                ><i className="bi bi-telephone-fill text-dark fs-3"></i></div>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <div>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.75" height="21.75" fill="currentColor" className="bi bi-crosshair2 text-muted" viewBox="0 0 16 16">
                                                                        <path d="M8 0a.5.5 0 0 1 .5.5v.518A7 7 0 0 1 14.982 7.5h.518a.5.5 0 0 1 0 1h-.518A7 7 0 0 1 8.5 14.982v.518a.5.5 0 0 1-1 0v-.518A7 7 0 0 1 1.018 8.5H.5a.5.5 0 0 1 0-1h.518A7 7 0 0 1 7.5 1.018V.5A.5.5 0 0 1 8 0m-.5 2.02A6 6 0 0 0 2.02 7.5h1.005A5 5 0 0 1 7.5 3.025zm1 1.005A5 5 0 0 1 12.975 7.5h1.005A6 6 0 0 0 8.5 2.02zM12.975 8.5A5 5 0 0 1 8.5 12.975v1.005a6 6 0 0 0 5.48-5.48zM7.5 12.975A5 5 0 0 1 3.025 8.5H2.02a6 6 0 0 0 5.48 5.48zM10 8a2 2 0 1 0-4 0 2 2 0 0 0 4 0" />
                                                                    </svg>
                                                                </div>
                                                                <p className="mb-0 ms-2 text-muted fs-6"><span className="text-dark fw-bold">{moreOrderDetails.pickup_address.split(' ').slice(0, 3).join(" ")}</span></p>
                                                            </div>
                                                            <p className=' my-0 fs-6 ms-2'>Delivery fee</p>
                                                        </div>

                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <i className="bi bi-geo-alt-fill text-primary p-0 m-0 fs-3"></i>
                                                                <p className="mb-0 ms-2 text-muted fs-6"><span className="text-dark fw-bold">{moreOrderDetails.receiver_address.split(' ').slice(0, 3).join(" ")}</span></p>
                                                            </div>
                                                            <p className='fw-bold my-0 fs-6 ms-2'>&#8358;&nbsp;{NumberToMoney(moreOrderDetails.amount)}</p>
                                                        </div>

                                                        <p className="mb-0 ps-2 text-muted fs-6 ">Package details</p>
                                                        <div className='d-flex justify-content-between align-items-center w-100 px-2 py-3 w3-light-gray w3-round-large mt-2'>
                                                            <p className="mb-0 ps-2 text-muted fs-6 fw-bold">{moreOrderDetails.package_name}</p>
                                                            <p className="mb-0 ps-2 text-primary fs-6 fw-bold" role='button'
                                                                onClick={() => setViewMoreModal(true)}
                                                            >View more</p>
                                                        </div>
                                                        <div>
                                                            <small className='text-muted'>Once you have picked up the package, please click on the "Picked Up" button.</small>
                                                        </div>
                                                        <div className='d-flex align-items-center justify-content-between'>

                                                            <BLOCKBUTTON
                                                                className={"btn-primary mt-1"}
                                                                onClick={() => {
                                                                    updateOrderStatus({ variables: { orderId: moreOrderDetails.id, status: 'In_Transit' } });
                                                                    setIsWaiting(false);
                                                                    setVerifyStatusFromMerchant(true);
                                                                }}
                                                            >
                                                                Picked Up
                                                            </BLOCKBUTTON>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <Menu /> */}
                                        </div>
                                    </>
                                )}
                                {step === 5 && (
                                    <>
                                        <div className={'brand-modal-map w3-animate-bottom'}>
                                            <div className='w-100 brand-modal-content w3-sand'>
                                                <div className='text-center pt-2 pb-2 fw-bold'><i className="bi bi-bell pe-2"></i>Picked Up - In Transit</div>
                                                <div className='container px-2 pt-3 bg-light pb-4' >
                                                    <div className='w3-animate-bottom'>

                                                        <div className='d-flex justify-content-between align-items-center mb-3'>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='rounded-circle d-flex align-items-center justify-content-center'
                                                                    style={{ width: '50px', height: '50px' }}
                                                                >
                                                                    <img src={moreOrderDetails.merchantDetails.photo ? getImage(moreOrderDetails.merchantDetails.photo) : userPhoto} alt={moreOrderDetails.sender_name || "Unknown"} className="w-100 h-100 rounded-circle w3-image w3-animate-zoom w3-hover-shadow" />
                                                                </div>
                                                                <div>
                                                                    <p className='fw-bold my-0 fs-6 ms-2'>{moreOrderDetails.sender_name ? moreOrderDetails.sender_name.split(' ')[0] : "Unknown"}</p>
                                                                    <p className=' my-0 fs-6 ms-2'>Distance - {distance}</p>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex align-items-center '>
                                                                <div className='w3-light-gray w3-circle d-flex justify-content-center align-items-center p-3 mx-2'
                                                                    style={{ width: '40px', height: '40px' }}
                                                                    onClick={() => {
                                                                        navigate("/chat-with-merchant")
                                                                    }}
                                                                ><i className="bi bi-chat-right-text text-dark fs-3 "></i></div>
                                                                <div className='w3-light-gray w3-circle d-flex justify-content-center align-items-center p-3 ms-2'
                                                                    style={{ width: '40px', height: '40px' }}
                                                                    onClick={() => {
                                                                        setVoiceCallOption(true);
                                                                    }}
                                                                ><i className="bi bi-telephone-fill text-dark fs-3"></i></div>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <div>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.75" height="21.75" fill="currentColor" className="bi bi-crosshair2 text-muted" viewBox="0 0 16 16">
                                                                        <path d="M8 0a.5.5 0 0 1 .5.5v.518A7 7 0 0 1 14.982 7.5h.518a.5.5 0 0 1 0 1h-.518A7 7 0 0 1 8.5 14.982v.518a.5.5 0 0 1-1 0v-.518A7 7 0 0 1 1.018 8.5H.5a.5.5 0 0 1 0-1h.518A7 7 0 0 1 7.5 1.018V.5A.5.5 0 0 1 8 0m-.5 2.02A6 6 0 0 0 2.02 7.5h1.005A5 5 0 0 1 7.5 3.025zm1 1.005A5 5 0 0 1 12.975 7.5h1.005A6 6 0 0 0 8.5 2.02zM12.975 8.5A5 5 0 0 1 8.5 12.975v1.005a6 6 0 0 0 5.48-5.48zM7.5 12.975A5 5 0 0 1 3.025 8.5H2.02a6 6 0 0 0 5.48 5.48zM10 8a2 2 0 1 0-4 0 2 2 0 0 0 4 0" />
                                                                    </svg>
                                                                </div>
                                                                <p className="mb-0 ms-2 text-muted fs-6"><span className="text-dark fw-bold">{moreOrderDetails.pickup_address.split(' ').slice(0, 3).join(" ")}</span></p>
                                                            </div>
                                                            <p className=' my-0 fs-6 ms-2'>Delivery fee</p>
                                                        </div>

                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <i className="bi bi-geo-alt-fill text-primary p-0 m-0 fs-3"></i>
                                                                <p className="mb-0 ms-2 text-muted fs-6"><span className="text-dark fw-bold">{moreOrderDetails.receiver_address.split(' ').slice(0, 3).join(" ")}</span></p>
                                                            </div>
                                                            <p className='fw-bold my-0 fs-6 ms-2'>&#8358;&nbsp;{NumberToMoney(moreOrderDetails.amount)}</p>
                                                        </div>

                                                        <p className="mb-0 ps-2 text-muted fs-6 ">Package details</p>
                                                        <div className='d-flex justify-content-between align-items-center w-100 px-2 py-3 w3-light-gray w3-round-large mt-2'>
                                                            <p className="mb-0 ps-2 text-muted fs-6 fw-bold">{moreOrderDetails.package_name}</p>
                                                            <p className="mb-0 ps-2 text-primary fs-6 fw-bold" role='button'
                                                                onClick={() => setViewMoreModal(true)}
                                                            >View more</p>
                                                        </div>
                                                        <div>
                                                            <small className='text-muted'>Once you have arrived the drop off location, please click on the "Arrived for Delivery" button.</small>
                                                        </div>
                                                        <div className='d-flex align-items-center justify-content-between'>

                                                            <BLOCKBUTTON
                                                                className={"btn-primary mt-1"}
                                                                onClick={() => {
                                                                    updateOrderStatus({ variables: { orderId: moreOrderDetails.id, status: 'Arrived_for_Delivery' } });
                                                                    setVerifyStatusFromMerchant(true);
                                                                }}
                                                            >
                                                                Arrived for Delivery
                                                            </BLOCKBUTTON>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <Menu /> */}
                                        </div>
                                    </>
                                )}
                                {step === 6 && (
                                    <>
                                        <div className={'brand-modal-map w3-animate-bottom'}>
                                            <div className='w-100 brand-modal-content w3-sand'>
                                                <div className='text-center pt-2 pb-2 fw-bold'><i className="bi bi-bell pe-2"></i>You have arrived at the delivery location</div>
                                                <div className='container px-2 pt-3 bg-light pb-4'>
                                                    <div className='w3-animate-bottom'>

                                                        <div className='d-flex justify-content-between align-items-center mb-3'>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='rounded-circle d-flex align-items-center justify-content-center'
                                                                    style={{ width: '50px', height: '50px' }}
                                                                >
                                                                    <img src={moreOrderDetails.merchantDetails.photo ? getImage(moreOrderDetails.merchantDetails.photo) : userPhoto} alt={moreOrderDetails.sender_name || "Unknown"} className="w-100 h-100 rounded-circle w3-image w3-animate-zoom w3-hover-shadow" />
                                                                </div>
                                                                <div>
                                                                    <p className='fw-bold my-0 fs-6 ms-2'>{moreOrderDetails.sender_name ? moreOrderDetails.sender_name.split(' ')[0] : "Unknown"}</p>
                                                                    <p className=' my-0 fs-6 ms-2'>Distance - {distance}</p>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex align-items-center '>
                                                                <div className='w3-light-gray w3-circle d-flex justify-content-center align-items-center p-3 mx-2'
                                                                    style={{ width: '40px', height: '40px' }}
                                                                    onClick={() => {
                                                                        navigate("/chat-with-merchant")
                                                                    }}
                                                                ><i className="bi bi-chat-right-text text-dark fs-3 "></i></div>
                                                                <div className='w3-light-gray w3-circle d-flex justify-content-center align-items-center p-3 ms-2'
                                                                    style={{ width: '40px', height: '40px' }}
                                                                    onClick={() => {
                                                                        setVoiceCallOption(true);
                                                                    }}
                                                                ><i className="bi bi-telephone-fill text-dark fs-3"></i></div>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <div>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.75" height="21.75" fill="currentColor" className="bi bi-crosshair2 text-muted" viewBox="0 0 16 16">
                                                                        <path d="M8 0a.5.5 0 0 1 .5.5v.518A7 7 0 0 1 14.982 7.5h.518a.5.5 0 0 1 0 1h-.518A7 7 0 0 1 8.5 14.982v.518a.5.5 0 0 1-1 0v-.518A7 7 0 0 1 1.018 8.5H.5a.5.5 0 0 1 0-1h.518A7 7 0 0 1 7.5 1.018V.5A.5.5 0 0 1 8 0m-.5 2.02A6 6 0 0 0 2.02 7.5h1.005A5 5 0 0 1 7.5 3.025zm1 1.005A5 5 0 0 1 12.975 7.5h1.005A6 6 0 0 0 8.5 2.02zM12.975 8.5A5 5 0 0 1 8.5 12.975v1.005a6 6 0 0 0 5.48-5.48zM7.5 12.975A5 5 0 0 1 3.025 8.5H2.02a6 6 0 0 0 5.48 5.48zM10 8a2 2 0 1 0-4 0 2 2 0 0 0 4 0" />
                                                                    </svg>
                                                                </div>
                                                                <p className="mb-0 ms-2 text-muted fs-6"><span className="text-dark fw-bold">{moreOrderDetails.pickup_address.split(' ').slice(0, 3).join(" ")}</span></p>
                                                            </div>
                                                            <p className=' my-0 fs-6 ms-2'>Delivery fee</p>
                                                        </div>

                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <i className="bi bi-geo-alt-fill text-primary p-0 m-0 fs-3"></i>
                                                                <p className="mb-0 ms-2 text-muted fs-6"><span className="text-dark fw-bold">{moreOrderDetails.receiver_address.split(' ').slice(0, 3).join(" ")}</span></p>
                                                            </div>
                                                            <p className='fw-bold my-0 fs-6 ms-2'>&#8358;&nbsp;{NumberToMoney(moreOrderDetails.amount)}</p>
                                                        </div>

                                                        <p className="mb-0 ps-2 text-muted fs-6 ">Package details</p>
                                                        <div className='d-flex justify-content-between align-items-center w-100 px-2 py-3 w3-light-gray w3-round-large mt-2'>
                                                            <p className="mb-0 ps-2 text-muted fs-6 fw-bold">{moreOrderDetails.package_name}</p>
                                                            <p className="mb-0 ps-2 text-primary fs-6 fw-bold" role='button'
                                                                onClick={() => setViewMoreModal(true)}
                                                            >View more</p>
                                                        </div>
                                                        <div>
                                                            <small className='text-muted'>Once you have arrived the delivery location and delivered the package, please click on the "Confirm Delivery" button.</small>
                                                        </div>
                                                        <div className='d-flex align-items-center justify-content-between'>

                                                            <BLOCKBUTTON
                                                                className={"btn-primary mt-1"}
                                                                onClick={() => {
                                                                    setStep(7)
                                                                    setVerifyStatusFromMerchant(false);
                                                                }}
                                                            >
                                                                Confirm Delivery
                                                            </BLOCKBUTTON>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <Menu /> */}
                                        </div>
                                    </>
                                )}
                                {step === 7 && (
                                    <ConfirmOrder />
                                )}
                                {step === 8 && (
                                    <CancelOrder setStep={setStep} />
                                )}

                            </>
                        )
                    }
                </>
            )}

            {moreOrderDetails && viewMoreModal && (
                <div
                    className={'brand-modal w3-animate-zoom w-100' + (viewMoreModal ? 'd-flex' : 'd-none')}
                    style={{ alignItems: 'center' }}
                >
                    <div
                        className='container bg-light p-3 mx-3'
                        style={{ borderRadius: '16px', maxWidth: '400px' }}
                    >
                        <div className='d-flex align-items-center justify-content-between w-100 px-2 border-bottom border-muted'>
                            <div className="">
                                <h3 className='text-start my-0 pb-1'>Package Details</h3>
                                <p className="text-start text-muted mb-1">Here is the package details</p>
                            </div>
                            <div className='w3-light-gray w3-circle d-flex justify-content-center align-items-center' style={{ width: '30px', height: '30px' }}>
                                <i className='bi bi-x fs-3'
                                    onClick={() => setViewMoreModal(false)}
                                    role='button'
                                ></i>
                            </div>
                        </div>
                        <div>
                            <div className="mt-3">
                                <p className="text-muted mb-0">Package Name</p>
                                <h3 className="text-dark my-0">{moreOrderDetails.package_name}</h3>
                            </div>
                            <div className="mt-3">
                                <p className="text-muted mb-0">Pick Up</p>
                                <h3 className="text-dark my-0">{moreOrderDetails.pickup_address}</h3>
                            </div>
                            <div className="mt-3">
                                <p className="text-muted mb-0">Drop Off</p>
                                <h3 className="text-dark my-0">{moreOrderDetails.receiver_address}</h3>
                            </div>
                            <div className="mt-3">
                                <p className="text-muted mb-0">Package Quantity</p>
                                <h3 className="text-dark my-0">{moreOrderDetails.package_quantity > 1 ? "Multiple" : "Single"} - {moreOrderDetails.package_quantity}</h3>
                            </div>
                            <div className="mt-3">
                                <p className="text-muted mb-0">Receiver's Name</p>
                                <h3 className="text-dark my-0">{moreOrderDetails.receiver_name}</h3>
                            </div>
                            <div className="mt-3">
                                <p className="text-muted mb-0">Receiver's Number</p>
                                <h3 className="text-dark my-0">{moreOrderDetails.receiver_phone}</h3>
                            </div>
                        </div>

                    </div>
                </div>
            )}
            {voiceCallOption && (
                <>
                    <div className="brand-modal">
                        <div className='w-100 brand-modal-content bg-light px-3'>
                            <h4 className='text-start py-3 my-0'>Call Merchant</h4>
                            <div className=''>
                                <div className='w-100 w3-round-large mb-3' style={{ width: '40px', height: '40px' }}
                                    onClick={() => {
                                        navigate("/call")
                                    }}>
                                    <div className='d-flex align-items-center justify-content-start'>
                                        <div className='w3-light-gray w3-circle d-flex justify-content-center align-items-center p-3'
                                            style={{ width: '40px', height: '40px' }}
                                        ><img src="./images/telephone-dark.png" alt="" className='ms-0' width={23} /></div>
                                        <p className='ms-3 mb-0'>Call in app</p>
                                    </div>
                                </div>

                                <div className='w-100 w3-round-large mb-4' style={{ width: '40px', height: '40px' }}
                                    onClick={() => {
                                        callUsingMobile();
                                    }}>
                                    <div className='d-flex align-items-center justify-content-start'>
                                        <div className='w3-light-gray w3-circle d-flex justify-content-center align-items-center p-3'
                                            style={{ width: '40px', height: '40px' }}
                                        ><img src="./images/telephone-dark.png" alt="" className='ms-0' width={23} /></div>
                                        <p className='ms-3 mb-0'>Call with mobile</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </>
            )}
            {locationUpdateNotifier && (
                <>
                    <div
                        className={'brand-modal w3-animate-zoom w-100' + (viewMoreModal ? 'd-flex' : 'd-none')}
                        style={{ alignItems: 'center' }}
                    >
                        <div
                            className='container bg-light p-3 mx-3'
                            style={{ borderRadius: '16px', maxWidth: '400px' }}
                        >
                            <div>
                                <h3 className='text-start my-0 pb-0'>New Delivery Address</h3>
                                <small className="text-start text-muted mb-1">The merchant has changed the delivery address. <br />Here are new details:</small>
                            </div>

                            <div>
                                <div className="mt-3">
                                    <p className="text-muted mb-0">New Address</p>
                                    <h3 className="text-dark my-0">{moreOrderDetails.receiver_address}</h3>
                                </div>
                                <div className="mt-3">
                                    <p className="text-muted mb-0">New Estimated Time</p>
                                    <h3 className="text-dark my-0">{moreOrderDetails.duration}</h3>
                                </div>
                                <div className="mt-3">
                                    <p className="text-muted mb-0">New Delivery Fee</p>
                                    <h3 className="text-dark my-0">&#8358;&nbsp;{NumberToMoney(moreOrderDetails.amount)}</h3>
                                </div>
                            </div>
                            <div>
                                <BLOCKBUTTON
                                    className={"btn-primary my-3"}
                                    onClick={() => {
                                        setLocationUpdateNotifier(false);
                                    }}
                                >
                                    Update
                                </BLOCKBUTTON>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default Dashboard;

const defaultOptions = {
    strokeOpacity: 0.5,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
};
const closeOptions = {
    ...defaultOptions,
    zIndex: 3,
    fillOpacity: 0.05,
    strokeColor: "#FF6600",
    fillColor: "#FF6600",
};