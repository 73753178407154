import React from "react";
import { useNavigate } from "react-router-dom";
import Menu from "./Menu";


const NotVerified = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="">
                <div className='bg-primary'>
                    <div className='d-flex align-items-center justify-content-between pt-4 pb-1'>
                        <div></div>
                        <div className='w3-center fs-3 text-light d-flex justify-content-center'>
                            <h3 className='w3-center text-light my-0'>GOkari</h3>
                        </div>
                        <div className='me-3'
                            onClick={() => navigate('/notification')}
                        >
                            <i className="bi bi-bell fs-3 text-end text-light"></i>
                        </div>
                    </div>
                </div>
                <div className="w3-animate-zoom container d-flex align-items-center justify-content-center "
                    style={{ height: '80vh' }}
                >
                    <div className="w3-center">
                        <img src="./images/no-package.png" alt="no-package" width={150} style={{ opacity: 0.5 }} />
                        <p className="text-muted mb-0 mt-4">You cannot access this option until you complete the registration process and your account is approved.</p>
                        <p className="text-muted">Kindly complete your account registration.</p>
                    </div>
                </div>
            </div>
            <Menu />
        </>
    )
};

export default NotVerified;