import React from 'react';
import { BLOCKBUTTON, BUTTON } from '../../../Components/Forms';
import { useNavigate } from 'react-router-dom';
import Session from '../../../Helpers/Session';
import { CANCEL_ORDER } from '../../../GraphQL/Mutations';
import { useMutation } from '@apollo/client';
import { APIResponse } from '../../../Helpers/General';


const CancelOrder = ({setStep}: {setStep: (step: number) => void}) => {
    const navigate = useNavigate();
    const [cancelStep, setCancelStep] = React.useState(0);
    const [order, setOrder] = React.useState(Session.get("selectedOrder"));
    const [cancellationReason, setCancellationReason] = React.useState("");
    const [isReasonSelected, setIsReasonSelected] = React.useState(false);
    const step = Session.get("step");



    const [cancelOrder] = useMutation(CANCEL_ORDER, {
        variables: {
            orderId: order?.id,
            cancellationReason: cancellationReason
        },
        onCompleted: (data) => {
            console.log(data);
            Session.showAlert({ str: 'Order Cancelled', type: 'success' });
            Session.remove("selectedOrder");
            Session.remove("step");
            window.location.href = '/';
        },
        onError: (error) => {
            APIResponse(error);
            Session.showAlert({ str: error.message, type: 'error' });
            return;
        }
    })

    const reasons = [
        "Wrong Address or missing details",
        "Recipient not available",
        "Package not ready",
        "Delivery vehicle problem",
        "Bad weather making delivery unsafe",
        "Customer asked to cancel or reschedule",
        "Had other options",
        "I changed my mind",
        "No reason whatsoever"
    ]


    const handleReason = (value: string) => {
        if (cancellationReason === value) {
            setCancellationReason("");
            setIsReasonSelected(false);
        } else {
            setCancellationReason(value);
            setIsReasonSelected(true);
        }
    }


    return (
        <>
            <div>
                {cancelStep < 1 ? (
                    <>
                        <div className={'brand-modal w3-animate-bottom d-flex'}>
                            <div className='w-100 brand-modal-content bg-light'>
                                <div className='px-2 pt-3'>
                                    <h3 className='fw-bold fs-4 my-0 pb-3'>Cancel Delivery</h3>
                                    <div>
                                        <p>Are you certain you want to cancel this delivery?</p>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <BUTTON
                                            className={"border border-dark border-1 w-50 mt-2 mb-3 "}
                                            onClick={() => setStep(step)}
                                        >
                                            Go Back
                                        </BUTTON>
                                        <div className='w-25'></div>
                                        <BUTTON
                                            className={"brand negative w-50 mt-2 mb-3 "}
                                            onClick={() => setCancelStep(1)}
                                        >
                                            Cancel
                                        </BUTTON>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={'brand-modal w3-animate-bottom d-flex'}>
                            <div className='container brand-modal-content bg-light'>
                                <div className='px-2 pt-3'>
                                    <div className='d-flex align-items-center justify-content-center w3-light-gray w3-circle' style={{ height: '40px', width: '40px' }}>
                                        <i className='bi bi-x fs-2'
                                            onClick={() => setCancelStep(0)}
                                        ></i>
                                    </div>
                                    <h3 className='fw-bold fs-4 my-0 pb-3 pt-3'>You want to cancel this delivery?</h3>
                                    {reasons.map((reason, index) => (
                                        <div className='d-flex align-items-center justify-content-between mb-3'
                                            key={index}
                                        >
                                            <p className='my-0'>{reason}</p>
                                            <input
                                                type="checkbox"
                                                className='form-check-input mt-0'
                                                checked={cancellationReason === reason}
                                                onChange={(e) => handleReason(e.target.checked ? reason : "")}
                                            />
                                        </div>
                                    ))}


                                    <div className='d-flex align-items-center'>

                                        <BLOCKBUTTON
                                            className={"brand negative w-100 mt-5 mb-3 "}
                                            disabled={!cancellationReason || !isReasonSelected}
                                            onClick={() => cancelOrder()}
                                        >
                                            Cancel Delivery
                                        </BLOCKBUTTON>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

            </div>

        </>
    );
}

export default CancelOrder;