import React from 'react';
import { Link, useNavigate } from 'react-router-dom';


const TermsAndPolicy = ({ onClick }: { onClick?: () => void }) => {
    const [step, setStep] = React.useState(1);
    const navigate = useNavigate();
    return (
        <>
            <div className="w3-animate-left">
                <div className='bg-primary w-100'>
                    <div className='d-flex align-items-baseline justify-content-between pt-4 pb-1'>
                        <div>
                            <i className="bi bi-arrow-left ps-3 fs-3 dark text-light fw-bolder"
                                onClick={onClick || (() => navigate(-1))}
                            ></i>

                        </div>
                        <div className='w3-center fs-3 text-light d-flex justify-content-center'>
                            <h3 className='w3-center text-light mb-0'>Policy and Privacy</h3>
                        </div>
                        <div className='me-3'></div>
                    </div>
                </div>
                <div className='container mx-2'>

                    <h2 className='text-center fs-2 text-primary'>Gokari Logistics On-Demand Terms and Conditions.</h2>
                    <h3 className='text-center fs-3'>GENERAL TERMS</h3>
                    <h4>KEY PRINCIPLES</h4>
                    <p>
                        PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING OR
                        REQUESTING THE SERVICES MADE AVAILABLE TO YOU VIA GOKARI LOGISTICS'
                        APPLICATION(S) / WEBSITE(S) AS THEY INCLUDE IMPORTANT INFORMATION ABOUT
                        THE SERVICES, YOUR RIGHTS, AND OTHER IMPORTANT INFORMATION.
                    </p>
                    <p>
                        These Terms apply to Gokari Logistics' mobile applications, websites, and
                        services provided in Nigeria.
                    </p>
                    <p>
                        The App(s)/Website(s) Services enable you to request services from Gokari
                        Logistics ("Gokari Provided Services") and from Third-Party Providers
                        ("Third Party Services"). Both Gokari Provided Services and Third Party
                        Services are provided or made available as Gokari Products.
                    </p>
                    <p>
                        For Gokari Provided Services, you are contracting directly with Gokari
                        Logistics for the provision of these services. For Third Party Services,
                        you are contracting directly with Third Party Providers, and Gokari
                        Logistics acts as an agent to enable you to request and receive those
                        services.
                    </p>
                    <p>
                        These Terms cover your use of Gokari Courier Delivery Services. For other
                        Gokari Products, you may be required to accept additional terms that
                        govern your use of those services.
                    </p>
                    <p>
                        In order to provide Gokari Courier Delivery Services, Gokari Logistics
                        accepts Bookings as a courier hire operator for the purpose of courier
                        hire legislation and regulation in Nigeria.
                    </p>
                    <p>
                        By agreeing to these Terms, you also agree to comply with our Community
                        Guidelines.
                    </p>
                    <p>ABOUT US</p>
                    <p>
                        We are Gokari Logistics Limited, a logistics service provider in Nigeria.
                    </p>
                    <h3>DEFINITIONS</h3>
                    <p>
                        We have given certain words a defined meaning. These are set out below:
                    </p>
                    <ul>
                        <li>
                            "App(s)/Website(s) Services" means the services provided to you by
                            Gokari Logistics via the mobile applications and websites.
                        </li>
                        <li>"Booking" means a booking of Gokari Courier Delivery Services.</li>
                        <li>
                            "Community Guidelines" mean the guidelines that set out expectations of
                            all users of the App(s)/Website(s) Services.
                        </li>
                        <li>
                            "Third Party Providers" are independent third parties that provide
                            services through the platform.
                        </li>
                        <li>
                            "Third Party Services" include services and goods in the field of
                            transportation, delivery, logistics, and more.
                        </li>
                        <li>"Gokari" means Gokari Logistics Limited.</li>
                        <li>
                            "Gokari Courier Delivery Service" means the provision of transport
                            delivery/ journeys accepted by Gokari Courier Delivery Application.
                        </li>
                        <li>
                            "Nigeria" refers to the geographical region where the services are
                            provided.
                        </li>
                    </ul>
                    <h3>TERMS</h3>
                    <p>1 Overview</p>
                    <p>
                        1.1 These terms cover your access and use of Gokari's App(s)/Website(s)
                        Services and your use of Gokari Products (the “Terms”).
                    </p>
                    <p>
                        1.2 You must read carefully and agree to the Terms before accessing and
                        using Gokari's App(s)/Website(s) Services or Gokari Products.
                    </p>
                    <p>
                        1.4 By using Gokari's App(s)/Website(s) Services, you confirm your
                        agreement to these Terms.
                    </p>
                    <p>2 Gokari Provided Services</p>
                    <p>
                        2.1 The specific terms governing Gokari Provided Services, other than
                        Courier Delivery, may be set out in additional terms or a separate
                        agreement. For Gokari Provided Services, Gokari is the service provider
                        contracting directly with you.
                    </p>
                    <p>3 Third Party Services</p>
                    <p>
                        3.1 For Third Party Services, Gokari acts as an agent between you and the
                        Third Party Providers to enable them to provide services to you.
                    </p>
                    <p>4 Privacy Notice</p>
                    <p>
                        4.1 Gokari collects, uses, and discloses information as described in
                        Gokari's Privacy Notice. Additional privacy notices may apply for specific
                        services. We will notify you when such notices apply to you.
                    </p>
                    <p>5 Charges and Payment</p>
                    <p>
                        5.1 Charges for Gokari Provided Services and Third Party Services will be
                        outlined in the respective service agreements.
                    </p>
                    <p>6 Cancellation</p>
                    <p>
                        6.1 Cancellation policies for Gokari Provided Services and Third Party
                        Services will vary. Specific terms regarding cancellations will be
                        provided. 6.2 In some cases, you do not have the right to cancel the
                        request for the Gokari Provided Services and Third Party Services once it
                        has been accepted. However, for certain GOkari Provided Services or Third
                        Party Services, Gokari or the Third Party Service Provider (as
                        appropriate) may allow you to cancel the request, but you may be charged a
                        cancellation fee.
                    </p>
                    <p>
                        6.2 Gokari may refuse requests and cancel the Third Party Services or
                        GOkari Provided Services if there is reasonable doubt about the
                        correctness or authenticity of the request or about the contact
                        information. In this case too, a cancellation fee may be charged. We may
                        also end any contract in place between you and Gokari.
                    </p>
                    <p>7 Termination</p>
                    <p>
                        7.1 You can terminate these Terms and the App(s)/Website(s) Services by
                        closing your account at any time.
                    </p>
                    <p>8 Your Liability and Indemnity</p>
                    <p>
                        8.1 You are responsible for any damage caused by your violation of these
                        Terms or misuse of the services.
                    </p>
                    <p>9 Limitation of Liability</p>
                    <p>
                        9.1 Gokari's liability is subject to legal limitations and exclusions, as
                        outlined in these Terms.
                    </p>
                    <p>10 General</p>
                    <p>
                        10.1 Gokari may update these Terms periodically. You will be notified of
                        any changes, and your continued use of the services constitutes acceptance
                        of the updated Terms.
                    </p>
                    <p>11 Dispute Resolution Process</p>
                    <p>
                        11.1 In the event of a dispute, please contact us using the provided
                        details for resolution.
                    </p>
                    <p>12 Governing Law and Jurisdiction</p>
                    <p>
                        12.1 These Terms are governed by the laws of Nigeria, and any legal
                        proceedings should be conducted within Nigerian courts.
                    </p>
                    <h3>APP(S)/WEBSITE(S) SERVICES</h3>
                    <p>
                        13 By accessing and using the App(s)/Website(s) Services, you accept these
                        Terms.
                    </p>
                    <p>14 Your access to and use of the App(s)/Website(s) Services</p>
                    <p>
                        14.1 The App(s)/Website(s) Services can be accessed on modern mobile
                        devices with an internet connection and compatible operating systems.
                    </p>
                    <p>15 Your Obligations when using the App(s)/Website(s) Services</p>
                    <p>
                        15.1 Users must comply with all applicable laws and use the services for
                        lawful purposes.
                    </p>
                    <p>16 Problem Resolution</p>
                    <p>
                        16.1 Contact us if you experience any issues or have complaints regarding
                        the services.
                    </p>
                    <p>17 Reliability of Information</p>
                    <p>
                        17.1 While efforts are made to update information, users should not solely
                        rely on the data available through the services.
                    </p>
                    <p>18 Ownership of the App(s)/Website(s) Services</p>
                    <p>18.1 Gokari reserves all rights to the services provided.</p>
                    <h3>GOKARI TRANSPORTATION SERVICES</h3>
                    <p>
                        19 Service Provider and Private Hire Operators of Gokari Transportation
                        Services
                    </p>
                    <p>
                        19.1 Gokari Logistics operates as the licensed courier hire operator for
                        Gokari Transportation Services.
                    </p>
                    <p>20 Gokari's Contract with you for Gokari Transportation Services</p>
                    <p>
                        20.1 Booking requests constitute an offer to purchase Gokari
                        Transportation Services in accordance with these Terms.
                    </p>
                    <p>21 Problem with Gokari Transportation Services</p>
                    <p>
                        21.1 Contact us for any issues or complaints regarding Gokari
                        Transportation Services.
                    </p>
                    <p>
                        These terms establish the framework for using Gokari Logistics' services.
                    </p>
                </div>
            </div>
        </>
    )
}

export default TermsAndPolicy