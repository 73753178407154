import React from "react";
import { Link } from "react-router-dom";
import { BLOCKBUTTON } from "../../../Components/Forms";
import { UPLOAD_COURIER_NIN_AND_LICENSE } from "../../../GraphQL/Mutations";
import { GET_DOCUMENT } from "../../../GraphQL/Queries";
import { useMutation, useQuery } from "@apollo/client";
import Session from "../../../Helpers/Session";
import { Loading } from "../../../Components/Loading";
import { getImage } from "../../../Helpers/FileHandling";
import Confirm from "../../../Components/Confirm";
import isImageBlurry from "../../../Helpers/isImageBlurry";

const ManageDocuments: React.FC = () => {
    const [nin, setNin] = React.useState('');
    const [licenseFront, setLicenseFront] = React.useState('');
    const [licenseBack, setLicenseBack] = React.useState('');
    const [selectImage, setSelectImage] = React.useState(false);
    const [selectedImageType, setSelectedImageType] = React.useState<string | null>(null);
    const [confirmEdit, setConfirmEdit] = React.useState(false);
    const [editSuccess, setEditSuccess] = React.useState(false);

    const { loading: fetchDocumentLoading, refetch } = useQuery(GET_DOCUMENT, {
        onCompleted: (data) => {
            if (data) {
                setNin(data?.getDocuments?.nin_front || '');
                setLicenseFront(data?.getDocuments?.riders_license_front || '');
                setLicenseBack(data?.getDocuments?.riders_license_back || '');
            }
        },
        onError: (error) => {
            Session.showAlert({ str: error.message, type: 'error' });
        }
    });

    React.useEffect(() => {
        refetch();
    }, [refetch]);

    const [uploadNinAndLicense, { loading: uploadNinAndLicenseLoading }] = useMutation(UPLOAD_COURIER_NIN_AND_LICENSE, {
        variables: {
            ridersLicenseFront: licenseFront,
            ridersLicenseBack: licenseBack,
            nin: nin
        },
        onCompleted: (data) => {
            if (data.uploadCourierLicenseAndNIN) {
                Session.showAlert({ str: 'NIN and license uploaded successfully', type: 'success' });
                setConfirmEdit(false);
                setEditSuccess(true);
            } else {
                Session.showAlert({ str: 'Something went wrong', type: 'error' });
            }
        },
        onError: (error) => {
            Session.showAlert({ str: error.message, type: 'error' });
        }
    });

    const handleImageUpload = (event: any) => {
        const file = event.target.files[0];
        const reader: any = new FileReader();
        reader.onload = async () => {
            if (selectedImageType === 'licenseFront') {
                let isBlurry = await isImageBlurry({ dataUrl: reader.result, threshold: 800 })
                if (isBlurry) {
                    Session.showAlert({ str: 'Please use a clear License Front image.', type: 'error' });
                    setLicenseFront("");
                    return;
                } else {
                    setLicenseFront(reader.result as string);
                }
            } else if (selectedImageType === 'licenseBack') {
                let isBlurry = await isImageBlurry({ dataUrl: reader.result, threshold: 800 })
                if (isBlurry) {
                    Session.showAlert({ str: 'Please use a clear License Back image.', type: 'error' });
                    setLicenseBack("");
                    return;
                } else {
                    setLicenseBack(reader.result as string);
                }
            } else if (selectedImageType === 'nin') {
                let isBlurry = await isImageBlurry({ dataUrl: reader.result, threshold: 800 })
                if (isBlurry) {
                    Session.showAlert({ str: 'Please use a clear NIN Card image.', type: 'error' });
                    setNin("");
                    return;
                } else {
                    setNin(reader.result as string);
                }
            }
        };
        reader.readAsDataURL(file);
    };

    const openCamera = () => {
        const input: any = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.capture = 'camera';
        input.onchange = handleImageUpload;
        input.click();
    };

    const openGallery = () => {
        const input: any = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = handleImageUpload;
        input.click();
    };

    const openDocuments = () => {
        const input: any = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = handleImageUpload;
        input.click();
    };

    const handleLicenceAndNINUpload = async () => {
        if (!licenseFront) {
            Session.showAlert({ str: 'Please upload your license front', type: 'warning' });
            return;
        }
        if (!licenseBack) {
            Session.showAlert({ str: 'Please upload your license back', type: 'warning' });
            return;
        }
        if (!nin) {
            Session.showAlert({ str: 'Please upload your NIN', type: 'warning' });
            return;
        }
        await uploadNinAndLicense();
    };

    if (fetchDocumentLoading) {
        return <Loading fullPage={true} message={'Fetching documents...'} timing={false} />;
    }

    if (uploadNinAndLicenseLoading) {
        return <Loading fullPage={true} message={'Uploading documents...'} timing={false} />;
    }

    return (
        <>
            <div className='w3-animate-left'>
                <div className='bg-primary'>
                    <div className='d-flex align-items-baseline justify-content-between pt-4 pb-1'>
                        <Link className="fs-3 text-light ms-3" to="/account">
                            <i className="bi bi-arrow-left"></i>
                        </Link>
                        <h3 className='w3-center text-light my-0'>Manage documents</h3>
                        <div className='me-3'></div>
                    </div>
                </div>
                <div className="container">
                    <div className=''>
                        <div className='mt-4'>
                            <p className='mb-0'>Upload a picture of your rider's license (Front).</p>
                            <div className='brand-card w3-animate-zoom w3-light-grey w3-round-xlarge border border-primary border-dashed upload-card d-flex justify-content-center align-items-center'
                                onClick={() => {
                                    setSelectImage(true);
                                    setSelectedImageType('licenseFront');
                                }}
                            >
                                {licenseFront ? (
                                    <img src={getImage(licenseFront)} alt="License Front" className="w-100 h-100 w3-round-xlarge" style={{ objectFit: 'cover' }} />
                                ) : (
                                    <div className='w3-center'>
                                        <i className="bi bi-credit-card-2-front-fill upload-card-icon text-center"></i>
                                        <p className='text-muted'>Upload Picture</p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='mt-4'>
                            <p className='mb-0'>Upload a picture of your rider's license (Back).</p>
                            <div className='brand-card w3-animate-zoom w3-light-grey w3-round-xlarge border border-primary border-dashed upload-card d-flex justify-content-center align-items-center'
                                onClick={() => {
                                    setSelectImage(true);
                                    setSelectedImageType('licenseBack');
                                }}
                            >
                                {licenseBack ? (
                                    <img src={getImage(licenseBack)} alt="License Back" className="w-100 h-100 w3-round-xlarge" style={{ objectFit: 'cover' }} />
                                ) : (
                                    <div className='w3-center'>
                                        <i className="bi bi-credit-card-2-front-fill upload-card-icon text-center mb-0"></i>
                                        <p className='text-muted'>Upload Picture</p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='mt-4 mb-4'>
                            <p className='mb-0'>Upload a picture of your NIN Card.</p>
                            <div className='brand-card w3-animate-zoom w3-light-grey w3-round-xlarge border border-primary border-dashed upload-card d-flex justify-content-center align-items-center'
                                onClick={() => {
                                    setSelectImage(true);
                                    setSelectedImageType('nin');
                                }}
                            >
                                {nin ? (
                                    <img src={getImage(nin)} alt="NIN Card" className="w-100 h-100 w3-round-xlarge" style={{ objectFit: 'cover' }} />
                                ) : (
                                    <div className='w3-center'>
                                        <i className="bi bi-credit-card-2-front-fill upload-card-icon text-center mb-0"></i>
                                        <p className='text-muted'>Upload Picture</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <BLOCKBUTTON className={'btn mt-2 mb-4 btn-primary'}
                        onClick={() => setConfirmEdit(true)}
                    >
                        Submit
                    </BLOCKBUTTON>

                </div>
                {confirmEdit && (
                    <Confirm key="edit" message={<>
                        <span>Are you sure you want to update your Documents?</span><br />
                        <span className="fw-bold text-muted">This will make your account Pending, you will need to contact customer support to re-activate your account.</span>
                    </>} onConfirm={() => handleLicenceAndNINUpload()} onCancel={() => setConfirmEdit(false)} topic="Update Documents?" cancelText="Cancel  " takeActionText="Save Changes" />
                )}
                {editSuccess && (
                    <div className={'brand-modal w3-animate-zoom d-flex align-items-center'}>
                        <div className='w-100 bg-light px-3 mx-2' style={{ borderRadius: '16px', maxWidth: '400px' }}>

                            <h3 className='w3-center text-warning fs-3 fw-bold mt-5 mb-3'>Review Ongoing</h3>

                            <p className='text-center mb-0 text-muted'>Your Documents have been successfully updated, and are currently under review. You will be notified once your documents has been approved.</p>

                            <BLOCKBUTTON
                                className="btn-primary mb-4 mt-5"
                                onClick={() => {
                                    setEditSuccess(false);
                                    window.location.reload();
                                }}
                            >
                                Continue
                            </BLOCKBUTTON>
                        </div>
                    </div>
                )}

                <div className={'brand-modal w3-animate-bottom ' + (selectImage ? 'd-flex' : 'd-none')}
                    onClick={() => setSelectImage(false)}
                >
                    <div className='container brand-modal-content bg-light'>
                        <h4 className='text-center py-3'>Choose From</h4>
                        <div className='d-flex justify-content-around align-items-center'>
                            <div className='w3-center icon-shadow p-2 w3-round-large'
                                onClick={openCamera}
                            >
                                <div className='d-flex flex-column'>
                                    <i className="bi bi-camera fs-3 text-muted"></i>
                                    <p>Camera</p>
                                </div>
                            </div>
                            <div className='w3-center icon-shadow p-2 w3-round-large'
                                onClick={openGallery}
                            >
                                <div className='d-flex flex-column'>
                                    <i className="bi bi-images fs-3 text-muted"></i>
                                    <p>Gallery</p>
                                </div>
                            </div>
                            <div className='w3-center icon-shadow p-2 w3-round-large'
                                onClick={openDocuments}
                            >
                                <div className='d-flex flex-column'>
                                    <i className="bi bi-file-earmark-richtext text-muted fs-3"></i>
                                    <p>Documents</p>
                                </div>
                            </div>
                        </div>
                        <input type="file" accept="image/*" onChange={handleImageUpload} style={{ display: 'none' }} id="fileInput" />
                        <BLOCKBUTTON
                            className='btn btn-primary mt-4 mb-3'
                            onClick={() => document.getElementById('fileInput')?.click()}
                        >Continue</BLOCKBUTTON>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ManageDocuments;
