import { gql } from '@apollo/client';


export const CURRENT_USER_COURIER = gql`
  query CurrentUserCourier {
  currentUserCourier {
    id
    fullname
    email
    phone
    photo
    balance
    category
    successful_orders
    courierDocumentStatus {
      nin_status
      riders_license_status
    }
    courierDetails {
      bike_photo
      bike_model
      bike_brand
      bike_year
      bike_plate_number
      status
      is_online
      is_busy
      last_seen
    }
    chat_details
    rating
    created_at
  }
}
`;

export const GET_CONFIG = gql`
query Query {
  getConfig
}
`;

export const NOTICE_ORDER_STATUS_AND_LOCATION = gql`
query Query($orderId: Int) {
  noticeOrderStatusAndLocation(order_id: $orderId)
}
`;


export const GET_ALL_COURIER_TRANSACTIONS = gql`
query CourierTransactions($offset: Int) {
  courierTransactions(offset: $offset) {
    id
    user_id
    order {
      id
      order_code
      user_id
      package_name
      package_quantity
      pickup_address
      receiver_name
      receiver_address
      receiver_phone
      rider_id
      rider_name
      status
      amount
      cancellation_reason
      created_at
      delivered_at
    }
    email
    code
    amount
    status
    remarks
    created_at
  }
}
`;
export const GET_COURIER_ORDERS = gql`
query GetCourierOrders($offset: Int) {
  getCourierOrders(offset: $offset) {
    id
    id
    order_code
    user_id
    package_name
    package_quantity
    pickup_address
    receiver_name
    receiver_address
    receiver_phone
    rider_id
    rider_name
    status
    amount
    cancellation_reason
    created_at
    delivered_at
    order_code
    user_id
    package_name
    package_quantity
    pickup_address
    receiver_name
    receiver_address
    receiver_phone
    rider_id
    rider_name
    status
    amount
    cancellation_reason
    created_at
    delivered_at
  }
}
`;
export const GET_ALL_NOTIFICATION = gql`
query GetAllNotifications($offset: Int) {
  getAllNotifications(offset: $offset) {
    id
    user_id
    message
    created_at
  }
}
`;

export const GET_DOCUMENT = gql`
query GetDocuments {
  getDocuments {
    id
    user_id
    nin_front
    nin_back
    nin_status
    riders_license_front
    riders_license_back
    riders_license_expiry
    riders_license_status
  }
}
`;
export const GET_COURIER_PENDING_ORDERS = gql`
query GetCourierPendingOrders {
  getCourierPendingOrders {
    id
    order_code
    user_id
    sender_name
    package_name
    package_quantity
    pickup_address
    receiver_name
    receiver_address
    receiver_phone
    distance
    duration
    rider_id
    rider_name
    status
    amount
    cancellation_reason
    created_at
    delivered_at
    merchantDetails
  }
}
`;