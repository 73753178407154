import React from 'react';
import UserContext from './Helpers/UserContext';
import { useQuery, useMutation } from '@apollo/client';
import { isLoggedIn } from './Helpers/IsLoggedIn';
import { CURRENT_USER_COURIER, GET_CONFIG } from './GraphQL/Queries';
import { UPDATE_CURRENT_LOCATION, GO_ONLINE, UPDATE_FIREBASE_TOKEN } from './GraphQL/Mutations';
import Session from './Helpers/Session';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Loading } from './Components/Loading';
import { Courier } from './types/CurrentCourier';
import { useJsApiLoader } from "@react-google-maps/api";
import UserCurrentLocation from './Helpers/UserCurrentLocation';
import { APIResponse } from './Helpers/General';

import Register from './Pages/Auth/Register';
import NotVerified from './Pages/MenuPages/NotVerified';
import Login from './Pages/Auth/Login';
import Verification from './Pages/Auth/Verification';
import Dashboard from './Pages/MenuPages/Dashboard';
import Account from './Pages/MenuPages/Account';
import TermsAndPolicy from './Pages/MenuPages/Account/Terms&Policy';
import ManageVehicle from './Pages/MenuPages/Account/ManageVehicle';
import CustomerSupport from './Pages/MenuPages/Account/CustomerSupport';
import Wallet from './Pages/MenuPages/Wallet';
import Withdraw from './Pages/MenuPages/Wallet/Withdraw';
import Transaction from './Pages/MenuPages/Wallet/Transaction';
import Notification from './Pages/MenuPages/Account/Notification';
import ManageDocuments from './Pages/MenuPages/Account/ManageDocuments';
import Order from './Pages/MenuPages/Order';
import ChatWithMerchant from './Pages/MenuPages/Dashboard/ChatWithMerchant';
import VoiceCall from './Pages/MenuPages/Dashboard/VoiceCall';
import Faqs from './Pages/MenuPages/Account/Faqs';
import { DateTime } from 'luxon';


const App: React.FC = () => {
    const [user, setUser] = React.useState<Courier | null>(null);
    const myContext = React.useContext(UserContext);
    const mii = myContext.user || Session.get('user');
    const [config, setConfig] = React.useState('{}');
    const loggedIn = isLoggedIn();
    const [userLocation, setUserLocation] = React.useState<{ latitude: number; longitude: number } | null>(null);

    useQuery(GET_CONFIG, {
        fetchPolicy: 'cache-and-network',
        onCompleted: data => {
            setConfig(data.getConfig);
            Session.setCookie('config', JSON.stringify(data.getConfig));
        }
    });
    const [isOnline, setisOnline] = React.useState<number>(mii?.courierDetails?.is_online);

    const [goOnline] = useMutation(GO_ONLINE, {
        onCompleted: (data) => {
            setisOnline(data.goOnline);
        }
    });

    const [updateFirebaseToken] = useMutation(UPDATE_FIREBASE_TOKEN)
    React.useEffect(() => {
        if(!loggedIn)return;
        window.scrollTo(0, 0);

        // Update firebase device token
        const today = DateTime.now().toISODate();
        const iosFirebase = Session.get('iosFirebase');
        if (Session.get('firebase_code_checked') !== today && iosFirebase?.length > 2) {
            updateFirebaseToken({ variables: { token: iosFirebase } });
            Session.set('iosFirebase', '');
            Session.set('firebase_code_checked', today);
        }

        if (Session.get('firebase_code_checked') !== today) {
            try {
                const code = Website2APK.getFirebaseDeviceToken();
                updateFirebaseToken({ variables: { token: code } });
                Session.set('firebase_code_checked', today);
            } catch (e) {
                console.log(e);
                /** */
            }
        }

    }, [loggedIn, updateFirebaseToken]);


    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
        libraries: ['places', 'maps', 'geocoding', 'marker', 'routes', 'places', 'drawing', 'visualization', 'geometry'],
        region: 'NG',
        language: 'en',
        version: 'weekly'
    });

    const { loading } = useQuery(CURRENT_USER_COURIER, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setUser(data.currentUserCourier);
            Session.setCookie('user', JSON.stringify(data.currentUserCourier));
            Session.set("user", data.currentUserCourier)
        }
    });
    const [updateCurrentLocation] = useMutation(UPDATE_CURRENT_LOCATION, {
        onError: (error) => {
            APIResponse(error);
        }
    });

    const updateLocation = React.useCallback(async () => {
        if (loggedIn) {
            try {
                const location = await UserCurrentLocation();
                if (
                    !userLocation ||
                    location.lat !== userLocation.latitude ||
                    location.lng !== userLocation.longitude
                ) {
                    setUserLocation({ latitude: location.lat, longitude: location.lng });
                    await updateCurrentLocation({
                        variables: {
                            lat: location.lat,
                            lng: location.lng
                        }
                    });
                }
            } catch (error) {
                console.error("Error updating location:", error);
            }
        }
    }, [loggedIn, userLocation, updateCurrentLocation]);

    React.useEffect(() => {
        updateLocation();
        const intervalId = setInterval(updateLocation, 20000);
        return () => clearInterval(intervalId);
    }, [updateLocation]);

    const updateOnlinePresence = React.useCallback(async () => {
        if (loggedIn && isOnline === 1) {
            try {
                await goOnline();
            } catch (error) {
                console.error("Error updating online presence:", error);
            }
        }
    }, [loggedIn, isOnline, goOnline]);

    React.useEffect(() => {
        updateOnlinePresence();
        const intervalId = setInterval(updateOnlinePresence, 10000);
        return () => clearInterval(intervalId);
    }, [updateOnlinePresence]);

    const shouldRedirectToVerify = () => {
        const { nin_status, riders_license_status }: any = user?.courierDocumentStatus || {};
        const { status, bike_model, bike_brand, bike_year, bike_plate_number }: any = user?.courierDetails || {};

        return (
            nin_status !== "APPROVED" ||
            riders_license_status !== "APPROVED" ||
            (status !== "Active" || (!bike_model || !bike_brand || !bike_year || !bike_plate_number))
        );
    };
    if (!loggedIn) {
        return (
            <Router>
                <Routes>
                    <Route path={'/register'} element={<Register />} />
                    <Route path="*" element={<Login />} />
                </Routes>
            </Router>
        );
    } else {
        if (!isLoaded || loading) return <Loading />;
        if (shouldRedirectToVerify()) {
            return (
                <UserContext.Provider value={{ user, setUser, config, setConfig }}>
                    <Router>
                        <Routes>
                            <Route path={'/'} element={<Verification />} />
                            <Route path={'/verify'} element={<Verification />} />
                            <Route path='/not-verified' element={<NotVerified />} />
                            <Route path={'/account'} element={<Account />} />
                            <Route path={'/managedocuments'} element={<ManageDocuments />} />
                            <Route path={'/notification'} element={<Notification />} />
                            <Route path={'/policy'} element={<TermsAndPolicy />} />
                            <Route path={'/managevehicle'} element={<ManageVehicle />} />
                            <Route path={'/faqs'} element={<Faqs />} />
                            <Route path={'/support'} element={<CustomerSupport />} />
                            <Route path="*" element={<Account />} />
                        </Routes>
                    </Router>
                </UserContext.Provider>
            );
        } else {
            return (
                <UserContext.Provider value={{ user, setUser, config, setConfig }}>
                    <Router>
                        <Routes>
                            <Route path={'/'} element={<Dashboard />} />
                            <Route path={'/dashboard'} element={<Navigate to="/" />} />
                            <Route path={'/account'} element={<Account />} />
                            <Route path={'/order'} element={<Order />} />
                            <Route path={'/managedocuments'} element={<ManageDocuments />} />
                            <Route path={'/notification'} element={<Notification />} />
                            <Route path={'/policy'} element={<TermsAndPolicy />} />
                            <Route path={'/managevehicle'} element={<ManageVehicle />} />
                            <Route path={'/faqs'} element={<Faqs />} />
                            <Route path={'/support'} element={<CustomerSupport />} />
                            <Route path={'/wallet'} element={<Wallet />} />
                            <Route path={'/wallet/withdraw'} element={<Withdraw />} />
                            <Route path={'/wallet/transaction'} element={<Transaction />} />
                            <Route path={'/chat-with-merchant'} element={<ChatWithMerchant />} />
                            <Route path={'/call'} element={<VoiceCall />} />
                            <Route path="*" element={<Dashboard />} />
                        </Routes>
                    </Router>
                </UserContext.Provider>
            );
        }

    }
};

export default App;
