import React, { useContext, startTransition, Suspense } from 'react';
import { Loading } from '../../../Components/Loading';
import UserContext from '../../../Helpers/UserContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_ALL_COURIER_TRANSACTIONS } from "../../../GraphQL/Queries";
import { APIResponse } from '../../../Helpers/General';
import Session from '../../../Helpers/Session';
import FormatDate from '../../../Helpers/FormatDate';
import NumberToMoney from '../../../Helpers/numberToMoney';
import { DateTime } from 'luxon';


const Transaction: React.FC = () => {
    const { user } = useContext(UserContext);
    const [transactions, setTransactions] = React.useState([]);
    const [activeTab, setActiveTab] = React.useState('All');
    const [offset, setOffset] = React.useState(0);
    const location = useLocation();
    const navigate = useNavigate();
    const name = user?.fullname;

    React.useEffect(() => {
        const tab = new URLSearchParams(location.search).get('tab') || 'All';
        setActiveTab(tab);
    }, [location.search]);

    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
        navigate({ search: `?tab=${tab}` });
    };
    const { refetch } = useQuery(GET_ALL_COURIER_TRANSACTIONS, {
        fetchPolicy: "network-only",
        variables: {
            offset: offset,
        },
        onCompleted: (data) => {
            console.log(data.courierTransactions);
            setTransactions(data.courierTransactions);
        },
        onError: (error) => {
            APIResponse(error);
            Session.showAlert({ str: error.message, type: 'error' });
            return;
        }
    });
    React.useEffect(() => {
        refetch();
    }, [offset]);

    const filteredTransactions = transactions.filter((transaction: Record<string, any>) => (activeTab === 'All' || transaction.status === activeTab));
    const formatTransaction = (transaction: Record<string, any>) => {
        if (transaction.status === 'Withdrawn') {
            return (
                <div className="w3-animate-zoom shadow-none px-3 mb-2 py-3 bg-light w3-round-large">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-center align-items-center w3-light-grey send-container w3-circle">
                                <i className="bi bi-arrow-down-left fs-3 text-negative"></i>
                            </div>
                            <div className="ms-3">
                                <p className="mb-0 fw-bold">{name}</p>
                                <p className="mb-0 text-muted">{FormatDate(transaction.created_at)}</p>
                            </div>
                        </div>
                        <div>
                            <p className="mb-0 text-negative fs-6">&#8358;&nbsp;{NumberToMoney(transaction.amount)}</p>
                        </div>
                    </div>
                </div>
            )
        } else if (transaction.order) {
            const order = transaction.order;
            return (
                <div className="w3-animate-zoom shadow-none px-3 mb-2 py-3 bg-light w3-round-large">
                    <div className="py-2"><p className="mb-0 text-muted">{DateTime.fromSQL(transaction.created_at).toFormat('cccc, dd LLL, yyyy')}</p></div>
                    <div className="position-relative">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.75" height="21.75" fill="currentColor" className="bi bi-crosshair2 text-muted" viewBox="0 0 16 16">
                                        <path d="M8 0a.5.5 0 0 1 .5.5v.518A7 7 0 0 1 14.982 7.5h.518a.5.5 0 0 1 0 1h-.518A7 7 0 0 1 8.5 14.982v.518a.5.5 0 0 1-1 0v-.518A7 7 0 0 1 1.018 8.5H.5a.5.5 0 0 1 0-1h.518A7 7 0 0 1 7.5 1.018V.5A.5.5 0 0 1 8 0m-.5 2.02A6 6 0 0 0 2.02 7.5h1.005A5 5 0 0 1 7.5 3.025zm1 1.005A5 5 0 0 1 12.975 7.5h1.005A6 6 0 0 0 8.5 2.02zM12.975 8.5A5 5 0 0 1 8.5 12.975v1.005a6 6 0 0 0 5.48-5.48zM7.5 12.975A5 5 0 0 1 3.025 8.5H2.02a6 6 0 0 0 5.48 5.48zM10 8a2 2 0 1 0-4 0 2 2 0 0 0 4 0" />
                                    </svg>
                                </div>
                                <p className="mb-0 ps-2 text-muted">Pick Up: <span className="text-dark fw-bold">{order.pickup_address}</span></p>
                            </div>
                            <div className="d-flex justify-content-center align-items-center w3-light-grey send-container w3-circle">
                                <i className="bi bi-arrow-up-right fs-3 text-success"></i>
                            </div>
                        </div>
                        {/* <i className="bi bi-three-dots-vertical text-muted p-0 m-0 fs-3"></i> */}
                        {/* <div className="vertical-line"></div> */}
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <i className="bi bi-geo-alt-fill text-primary p-0 m-0 fs-3"></i>
                                <p className="mb-0 ps-2 text-muted">Drop Off: <span className="text-dark fw-bold">{order.receiver_address}</span></p>
                            </div>
                            <div>
                                <p className="mb-0 text-success fs-6">&#8358;&nbsp;{NumberToMoney(transaction.amount)}</p>
                            </div>
                        </div>

                    </div>
                </div>
            )
        }
    }
    return (
        <>

            <div className='w3-animate-left'>
                <div className="bg-primary w-100">
                    <div className='d-flex align-items-baseline justify-content-between pt-4 pb-1'>
                        <div>
                            <Link className="dark fs-3 ps-3" to="/wallet">
                                <i className="bi bi-arrow-left text-light fw-bolder"></i>
                            </Link>
                        </div>
                        <div className='w3-center fs-3 text-light d-flex justify-content-center'>
                            <h3 className='w3-center text-light mb-0'>Transactions</h3>
                        </div>
                        <div className='me-3'></div>
                    </div>
                </div>
                <div className="my-2">
                    <div className='d-flex align-items-center justify-content-around overflow-auto'>
                        <div
                            className={`transaction-type w3-round-xxlarge shadow-none py-2 px-3 d-flex align-items-center justify-content-center ms-2 ${activeTab === 'All' ? 'bg-primary' : 'bg-light'}`}
                            onClick={() => handleTabClick('All')}
                        >
                            <span className={`fw-bold ${activeTab === 'All' ? 'text-light' : 'text-dark'}`}>All</span>
                        </div>
                        <div className={`transaction-type w3-round-xxlarge shadow-none py-2 px-3 d-flex align-items-center justify-content-center ms-2 ${activeTab === 'Received' ? 'bg-primary' : 'bg-light'}`}
                            onClick={() => handleTabClick("Received")}
                        >
                            <span className={`fw-bold ${activeTab === 'Received' ? 'text-light' : 'text-dark'}`}>Paid</span>
                        </div>
                        <div className={`transaction-type w3-round-xxlarge shadow-none py-2 px-3 d-flex align-items-center justify-content-center ms-2 ${activeTab === 'Withdrawn' ? 'bg-primary' : 'bg-light'}`}
                            onClick={() => handleTabClick("Withdrawn")}
                        >
                            <span className={`fw-bold ${activeTab === 'Withdrawn' ? 'text-light' : 'text-dark'}`}>Withdraw</span>
                        </div>
                    </div>
                </div>
                <Suspense fallback={<Loading />}>
                    <div className="container my-3">


                        {filteredTransactions.length > 0 ? (
                            filteredTransactions.map((transaction: Record<string, any>) => formatTransaction(transaction))
                        ) : (
                            <>

                            </>
                        )}


                    </div>
                </Suspense>
            </div>

        </>
    );
};

export default Transaction;